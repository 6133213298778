import { API_URL } from "../../../env"

export const getTableByNumService = async (numTable) => {
  try {
    const response = await fetch(`${API_URL}/api/tables/show?num_table=${numTable}`);
    if (!response.ok) {
      throw new Error('Failed to fetch table data');
    }
    const data = await response.json();
    return data.table;
  } catch (error) {
    throw error;
  }
};