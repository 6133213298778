import { useState } from 'react'

export default function CustomTipModal({
    title,
    setShowCustomTipModal,
    setPourboire
}) {

    const [customTipValue, setCustomTipValue] = useState('');
    const [messageTipValueError, setMessageTipValueError] = useState('');
    const handleChangeCustomTip = (event) => {
        setCustomTipValue(event.target.value)
    }

    const handleSubmitCustomTipValue = (event) => {
        event.preventDefault();
        if(customTipValue !== '' && !isNaN(customTipValue)){
            setPourboire(customTipValue);
            setShowCustomTipModal(false)
        }else{
            setMessageTipValueError('Les données sont pas valides, veuillez vérifier !')
        }
    }
    return (
        <div
            className="modal fade show"
            id="shareModal"
            tabindex="-1"
            aria-labelledby="shareModalLabel"
            aria-hidden="true"
            style={{
                // this stye what make modal display
                display: 'block',
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
            }}
        >
            <div class="modal-dialog"
                style={{
                    position: "fixed",
                    bottom: -15,
                    left: "49%",
                    transform: "translateX(-50%)",
                    width: "100%",
                }}
            >
                <div class="modal-content"
                    style={{ borderColor: "none", }}
                >
                    <div class="modal-header">
                        <h5
                            class="modal-title text tex-center"
                            id="shareModalLabel"
                            style={{
                                fontFamily: "Poppins",
                                fontSize: "22px",
                                fontWeight: "600",
                            }}
                        >{title}</h5>
                        <img
                            src="images/close-icon.svg"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            alt="close-modal-icon"
                            style={{ cursor: "pointer" }}
                            onClick={() => setShowCustomTipModal(false)}
                        />
                    </div>
                    <div class="modal-body">
                        <div class="d-grid gap-2">
                            <form onSubmit={handleSubmitCustomTipValue}>
                                <div className='mb-2'>
                                    <label htmlFor='coupon-name'></label>
                                    <input type='text' className='form-control' onChange={handleChangeCustomTip}/>
                                </div>
                                <div>
                                    {messageTipValueError && 
                                        <div 
                                            className='text text-danger'
                                            onClick={() => setMessageTipValueError("")}
                                            style={{ textAlign: 'left', fontWeight: 400 }}
                                        >
                                            {messageTipValueError}
                                        </div>
                                    }
                                </div>
                                <div class="d-grid gap-2 col-6 mx-auto">
                                    <button class="" 
                                        type="submit"
                                        style={{
                                            fontSize: "14px",
                                            fontWeight: "500",
                                            fontFamily: "Poppins",
                                            borderRadius: "20px",
                                            marginTop: "5px",
                                            padding: "10px",
                                            background: "#000000",
                                            color: '#FFFFFF',
                                            cursor: 'pointer',
                                            opacity: customTipValue === '' ? 0.5 : 1
                                        }}
                                        disabled={customTipValue === ''}
                                    >Valider </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
