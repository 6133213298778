import React, { useEffect } from 'react'
import { useLocation, Link } from 'react-router-dom';
import './Home.css'
import { useDispatch, useSelector } from 'react-redux';
import { addOperationInfos } from '../redux/operationSlice';
import { getTableByNumService } from '../common/apiServices/tables/table.service';

export default function Home() {
  // get table Number URL-Query
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const num_table = useSelector((state) => state?.operation?.operationInfos?.tableInfos?.num_table)
  const tabNumUrl = queryParams && queryParams.get('tab_num');

  const dispatch = useDispatch();

  useEffect(() => {
    if(tabNumUrl){
      console.log(tabNumUrl)
      getTableByNumService(tabNumUrl)
      .then((foundTable) => {
  
        // dispatch actions to store table infos in reduxStore with localstorage because not Auth Sytem to recuperate past data
        let storageOperations = JSON.parse(localStorage.getItem('operationInfos')) || { };
        storageOperations = {...storageOperations, tableInfos: {
          ...foundTable,
          // type_payment_table: null,
          // type_shared_payment: null
        }};
        localStorage.setItem('operationInfos', JSON.stringify(storageOperations));
        dispatch(addOperationInfos(storageOperations))
      })
      .catch((err) => console.log(err))
    }
  },[])

  return (
    <div className='container-fluid home'>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{
          textAlign: 'center',
          marginTop: '110px'
        }}
        >
          <img src="images/logo-paul.svg" alt="" />
        </div>
        <div style={{
          marginTop: '100px',
          display: "flex",
          flexDirection: "column",
          alignItems: 'center'
        }}
        >
          <div style={{ width: '330px', height: '72px' }}>
            <h3 className='text text-center text-white' 
                style={{ 
                    textAlign: 'center',
                    fontSize: '40px',
                    fontWeight: '500',
                    fontFamily:"Poppins",
                    marginTop:"10px"
                }}
            >
              Table #{num_table && num_table} 
              {/* Table #{1}  */}
              <img src="/images/quotes.svg" style={{marginBottom:"35px", color:"white"}} alt="" />
            </h3>
          </div>
          <div className='mt-5'>
            <Link to="/food-menu" className=''
              style={{
                width: '330px',
                height: '72px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                borderRadius: '10px',
                marginTop: '16px',
                paddingLeft: '15px',
                textDecoration:'none',
                background: "linear-gradient(to right, #FCC546, #FDD681)",
              }}
            >
              <h3 className='text' style={{ color: '#1E1E1E', fontSize: '20px' }}>
                Voir le menu
              </h3>
              <img src="images/icon-button-right.svg" alt="" />
            </Link>
            <Link to="/payment" className='bg-light'
              style={{
                width: '330px',
                height: '70px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                borderRadius: '10px',
                marginTop: '25px',
                marginBottom: '25px',
                paddingLeft: '15px',
                textDecoration:'none'
              }}
            >
              <h3 className='' style={{ color: 'black', fontSize: '20px', marginRight:"4px" }}>
                Payer l'addition
              </h3>
              <img src="images/logos_apple-pay.svg" alt="" />
              <img src="images/logos_mastercard.svg"  alt="" />
              <img src="images/logos_visa.svg"  alt="" />
              <img src="images/icon-button-right.svg"  alt="" />
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}
