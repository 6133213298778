import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    cartItems: JSON.parse(localStorage.getItem('cart')) || { products: [], count: 0 },
}

export const cartSlice = createSlice({
    name: 'cart',
    initialState,
    reducers: {
        addToCart(state, action){
            return {...state,
                cartItems : action.payload
            };
        },
        incrementQnt(state, action){
            return {...state,
                cartItems : action.payload
            };
        },
        decrementQnt(state, action){
            return {...state,
                cartItems : action.payload
            };
        },
        removeFromCart(state, action){
            return {...state,
                cartItems : action.payload
            };
        }
    }
});


export const { addToCart, incrementQnt, decrementQnt, removeFromCart } = cartSlice.actions;

export default cartSlice.reducer;