import { API_URL } from "../../../env"

export const verifyValidityCouponService = async (bodeyObj) => {
    return fetch(`${API_URL}/api-bo/coupons/verify-validity/`,{
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(bodeyObj),
    })
    .then((res) => res.json())
  }
  