import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { getReviewSettings } from '../common/apiServices/reviews/review.service'

export default function PaymentSuccess() {
    const navigate = useNavigate()
    useEffect(() => {
        // count 3 seconds Then redirect depends on if reviewStep is Active
        setTimeout(() => {
            // call api ti get info if reviewStep active
            getReviewSettings()
                .then((data) => {
                    console.log(data.settings);
                    const {isReviewStepActive} = data.settings[0]
                    if(isReviewStepActive === 'N'){
                        navigate('/review-stars/no')
                    }else{
                        // open Modal of review-Start depends on query isReview
                        navigate('/review-stars/yes')
                    }
                })
                .catch((err) => console.log(err))
            // redirect to ome page if reviewStep OFF
        },3000)
    })
  return (
    <div style={{
        background: '#BEF2BE',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
    }}>
        <div className='text text-center'>
            <img src='/images/payment_success_icon.png' />
            <h1 style={{fontSize: 48, fontWeight: 600, color: '#10C600'}}>
                Paiement réussi 
            </h1>
        </div>
    </div>
  )
}
