const { API_URL } = require("../../env")

export const createOperationService = (bodyData) => {
    return fetch(`${API_URL}/api-bo/operations/create`, {
        method: "POST",
        headers:{
            "Content-Type": "application/json"
        },
        body: JSON.stringify(bodyData),
    })
}

export const createOperationSharedByChoiceService = (bodyData) => {
    return fetch(`${API_URL}/api-bo/operations/shared-by-choice/create`, {
        method: "POST",
        headers:{
            "Content-Type": "application/json"
        },
        body: JSON.stringify(bodyData),
    })
}

export const createOperationSharedBySplitService = (bodyData) => {
    return fetch(`${API_URL}/api-bo/operations/shared-by-split/create`, {
        method: "POST",
        headers:{
            "Content-Type": "application/json"
        },
        body: JSON.stringify(bodyData),
    })
}
