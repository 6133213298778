import { API_URL } from "../../env"

export const getProducts = () => {
    return fetch(`${API_URL}/api/products/show`)
    .then((res) => res.json())
    .then((data) => data.products)
}
export const getOneProduct = (id) => {
    return fetch(`${API_URL}/api/products/show/${id}`)
    .then((res) => res.json())
    .then((data) => data.product)
}
export const getProductsByCategory = (id) => {
    return fetch(`${API_URL}/api/products/show-by-category/${id}`)
    .then((res) => res.json())
    .then((data) => data.products)
}