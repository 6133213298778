import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

export default function SubHeader({
  redirectTo,
  subHeaderTitle
}) {
  const { num_table } = useSelector((state) => state?.operation?.operationInfos?.tableInfos)

  return (
    <div className="container-fluid bg-white"
      style={{
        height: "80px",
        marginTop: "10px",
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        position: 'sticky',
        top: '80px',
        // top should have the same value of Header height
        zIndex: 100,
      }}
    >
      <div>
        <Link to={redirectTo}>
          <img src="/images/icon-arrow-left.svg" alt="icon-arrow-left" />
        </Link>
      </div>
      {
        subHeaderTitle ?
          <h3 style={{
            fontSize: '25px',
            textAlign: "center",
            fontWeight: 700,
            textDecoration:"underline"
          }}
          > {subHeaderTitle}
          </h3>
          :
          <>
            <h3 style={{
              fontSize: '23px',
              textAlign: "center",
            }}
            >
              {`Table #${num_table} `}
              <img src="/images/quotes.svg" style={{ marginBottom: "35px" }} alt="" />
            </h3>
          </>
      }

      <div>
        {/* empty div */}
      </div>
    </div>
  )
}
