import React from 'react'
import GenericListProducts from '../common/components/GenericListProducts'

export const lineStyle = {
  width: '60px',
  height: '6px',
  marginBottom: '10px',
  background: `linear-gradient(to right, #FCC546, #FDD681)`,
  marginLeft:"10px",
  borderRadius: "10px"
};

export default function MostPopularProducts({mostPopularProducts}) {
  
  return (
    <div className='container-fluid bg-white' >
      <div style={{display:"flex"}}>
        <img src="images/fire.svg" width={25} height={25} alt="fire-icon" />
        <h3 style={{
                fontFamily:"Poppins",
                fontSize: '18px',  
                marginTop:'5px' ,

        }}>
           Most Popular
        </h3>
        </div>
        <div style={lineStyle}></div>
        {
          mostPopularProducts && mostPopularProducts.map((item) => {
            return (
              <>
              <GenericListProducts product={item}/>
             </>
            )
          })
        }
        
    </div>
  )
}
