import { countAllTotals, formatPrice, getAllProductsFromAllOrdersByTable, removeDuplicatedObjectFromArray } from './../common/helpers/utils';
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { useEffect, useState } from 'react';
import { addNewPersonSharedCartEditModeByTableService, addSplitAmountToPayService, getCartSharedSplitItemsByTableService, removePersonSharedCartEditModeByTableService } from '../common/apiServices/cart/cart.service';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { addOperationInfos } from '../redux/operationSlice';
import { addNewPersonSharedSplitByTableService, addSplittedAmountToPayToSharedOrdersService, getOrdersByTableService, removePersonFromSharedSplitByTableService } from '../common/apiServices/orders/order.service';

export default function SplitEqualPaymentModal({
    title,
    setShowSplitPaymentModal,
}) {

    const tableInfosSelector = useSelector((state) => state?.operation?.operationInfos?.tableInfos)
    const { id_client } = useSelector((state) => state?.operation?.operationInfos?.clientInfos)
    const {id_table, type_shared_payment, type_payment_table} = useSelector((state) => state?.operation?.operationInfos?.tableInfos)
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [ordersDataByActiveTable, setOrdersDataByActiveTable] = useState([])
   
    const [selectedSharedTypeByTable, setSelectedSharedTypeByTable] = useState(null);

    //
    const [totalToPay, setTotalToPay] = useState(400);
    const [restAmountToShare, setRestAmountToShare] = useState(0);

    const [numberPersons, setNumberPersons] = useState(2);  // MVP is limited (Cant' changed by User)
    const [numberPersonsForPay, setNumberPersonsForPay] = useState(1); // by default Pay for himself

    // const percentage = 100 / numberPersons;
    const [convertedAmountPerPersonToPercentage, setConvertedAmountPerPersonToPercentage] = useState(100 / numberPersons);

    const [showMessageNotUpdateData, setShowMessageNotUpdateData] = useState('')
    const [isRefreshData, setIsRefreshData] = useState(false)


    const handleIncrementNumberPersonsForPay = () => {
        if (numberPersonsForPay + 1 > numberPersons) {
            // force limited number persons for pay on person table
            setNumberPersonsForPay(numberPersons)
        } else {
            const newNumberPersonsForPay = numberPersonsForPay + 1;
            setNumberPersonsForPay(newNumberPersonsForPay);

            // update percentage
            setConvertedAmountPerPersonToPercentage((100 / numberPersons) * newNumberPersonsForPay)
        }
    }

    const handleDecrementNumberPersonsForPay = () => {
        // check total & numberPersons
        if (numberPersonsForPay - 1 < 1) {
            // force limited number persons for pay on person table
            setNumberPersonsForPay(1)
        } else {
            const newNumberPersonsForPay = numberPersonsForPay - 1;
            setNumberPersonsForPay(newNumberPersonsForPay);

            // update percentage
            setConvertedAmountPerPersonToPercentage((100 / numberPersons) * newNumberPersonsForPay)
        }
    }

    const handleConfirmSplittedAmountToPay = () => {
        // here call api to confirm first that client is updated with data (check only total same is db)
        // check total & numberPersons
        getOrdersByTableService(id_table)
        .then((ordersData) => {
           setOrdersDataByActiveTable(ordersData);
           setSelectedSharedTypeByTable(ordersData[0].type_shared_payment);
           //
           setNumberPersons(ordersData[0]?.numberOfPersons);
           const totalResult = countAllTotals(getAllProductsFromAllOrdersByTable(ordersData));
           setTotalToPay(totalResult);
           //

           const newTotalToCheck = countAllTotals(getAllProductsFromAllOrdersByTable(ordersData));
           if(ordersData[0]?.numberOfPersons !== numberPersons || newTotalToCheck !== totalToPay){
               setShowMessageNotUpdateData('Les données sont pas mises à jour, veuillez ');
               return ;
           }else{
               const bodyData = {
                   id_table: id_table,
                   id_client_splitting_amount: id_client,
                   splitedAmountToPay: (totalToPay / numberPersons) * numberPersonsForPay,
                   ordersArr: ordersDataByActiveTable.map((item) => item.id_order)
               }
               addSplittedAmountToPayToSharedOrdersService(bodyData)
               .then((data) => {
                   if(data?.code === 1){
                       // store splittdAmountToPay in store & redirect to payment process to show it.
                       let storageOperationsData = JSON.parse(localStorage.getItem('operationInfos')) || {};
                       storageOperationsData = {
                           ...storageOperationsData,
                           clientInfos: {
                               ...storageOperationsData.clientInfos,
                               splittedAmountToPay: data?.splitedAmountToPay
                           },
                       };
                       localStorage.setItem('operationInfos', JSON.stringify(storageOperationsData));
                       dispatch(addOperationInfos(storageOperationsData))
       
                       // redirect to 'selected-cart-items-to-pay'
                       navigate('/payment-process')
                   }
               })
               .then((err) => console.log(err))
           }

        })
        .catch((err) => console.log(err))
    }

    useEffect(() => {
         // call api to get all order by table which is ON.
         getOrdersByTableService(id_table)
         .then((ordersData) => {
            setOrdersDataByActiveTable(ordersData);
            setSelectedSharedTypeByTable(ordersData[0].type_shared_payment);
            //
            setNumberPersons(ordersData[0]?.numberOfPersons);
            const totalResult = countAllTotals(getAllProductsFromAllOrdersByTable(ordersData));
            setTotalToPay(totalResult);

         })
         .catch((err) => console.log(err))
    }, [])

    const handleAddNewPersonSharedCartEditoMode = () => {
       if(ordersDataByActiveTable.length > 0){
         // call api and update UI if sucess
         addNewPersonSharedSplitByTableService(tableInfosSelector?.id_table)
         .then((res) => {
             if(res.code && res.code === 1){
                 setNumberPersons(numberPersons + 1)
             }
         })
         .catch((err) => console.log(err))
       }
    }

    const handleRemovePersonFromSharedCart = () => {
       if(ordersDataByActiveTable.length > 0){
         // call api to remove person from sharedSplit
        removePersonFromSharedSplitByTableService(tableInfosSelector?.id_table)
        .then((res) => {
            if(res.code && res.code === 1){
                setNumberPersons(numberPersons - 1)
            }
        })
        .catch((err) => console.log(err))
       }
    }

    return (
        <div
            className="modal fade show"
            tabindex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
            style={{
                display: 'block',
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
            }}
        >
            <div className="modal-dialog"
                style={{
                    position: "fixed",
                    bottom: -15,
                    left: "49%",
                    transform: "translateX(-50%)",
                    width: "100%",
                }}
            >
                <div className="modal-content"
                    style={{ borderColor: "none", }}
                >
                    <div className="modal-header" style={{
                        display: 'flex'
                    }}>
                        <img
                            src="/images/icon-arrow-left.svg"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            alt="close-modal-icon"
                            onClick={() => setShowSplitPaymentModal(false)}
                            style={{ cursor: "pointer" }}
                        />
                        <h5 className="modal-title text tex-center" id="customPourboireModalLabel"
                            style={{
                                fontFamily: "Poppins",
                                fontSize: "22px",
                                fontWeight: "600",
                            }}
                        >{title}</h5>
                        <div></div>
                    </div>
                    <div className="modal-body">
                        <div style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignContent: 'center'
                        }}
                        >
                            <div style={{
                                width: '170px',
                                height: '160px',
                                padding: '10px'
                            }}
                            >
                                <CircularProgressbar
                                    value={convertedAmountPerPersonToPercentage}
                                    text={`${formatPrice(totalToPay)} Dhs`}
                                    styles={buildStyles({
                                        textSize: '12px',
                                        textColor: 'black',
                                        pathColor: `#3E98C7`,
                                        trailColor: '#d6d6d6',
                                    })}
                                />
                            </div>
                        </div>
                        <div style={{
                            textAlign: 'center',
                            color: 'grey',
                            fontSize: '16px',
                            fontWeight: 600,
                            padding: '3px',
                            margin: '8px'
                        }}>
                            {`Le reste à partager : ${formatPrice(restAmountToShare)} Dhs`}
                            {/* {sharedItemsBySplitOptions.find((item) => item !== null)}
                            {sharedItemsBySplitOptions?.reduce((sum, item) => sum + item.splittedAmountToPay, 0) 
                                ? sharedItemsBySplitOptions?.reduce((sum, item) => sum + item.splittedAmountToPay, 0)
                                : "00.00 Dh"
                            } */}
                        </div>
                        <div style={{
                            marginTop: '8px',
                            display: 'flex',
                            justifyContent: 'center',
                            flexDirection: 'column',
                            alignContent: 'center',
                        }}>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    padding: '3px',
                                    // width: '65%'
                                }}
                            >
                                <div style={{ flex: 1, textAlign: 'left' }}>Payer pour</div>
                                {/* new here */}
                                <div style={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: "5px" }}>
                                    <div style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        border: 'solid black 1px',
                                        borderRadius: "30px",
                                        width: "110px",
                                        padding: "9px"
                                    }}>
                                        <div style={{ cursor: "pointer" }}
                                            onClick={handleDecrementNumberPersonsForPay}
                                        >
                                            <img src="/images/icon_decrement.svg" width={28} height={28}/>
                                        </div>
                                        <div style={{ textAlgin: 'center', fontWeight: 600, fontSize: ' 16px' }}>
                                            {numberPersonsForPay}
                                        </div>
                                        <div style={{ cursor: "pointer" }}
                                            onClick={handleIncrementNumberPersonsForPay}
                                        >
                                            <img src="/images/icon_increment.svg" width={28} height={28}/>
                                        </div>
                                    </div>
                                </div>
                                {/* end here */}
                                <div style={{ flex: 1, textAlign: 'right' }}>Personne(s)</div>
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    padding: '3px',
                                }}
                            >
                                <div style={{ flex: 1, textAlign: 'left' }}>Sur</div>
                                <div style={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: "5px" }}>
                                    <div style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        border: 'solid black 1px',
                                        borderRadius: "30px",
                                        width: "110px",
                                        padding: "9px"
                                    }}>
                                        <div style={{ cursor: "pointer" }}
                                            onClick={handleRemovePersonFromSharedCart} 
                                        >
                                            <img src="/images/icon_decrement.svg" width={28} height={28}/>
                                        </div>
                                        <div style={{ textAlgin: 'center', fontWeight: 600, fontSize: ' 16px' }}>
                                            {numberPersons}
                                        </div>
                                        <div style={{ cursor: "pointer" }}
                                        onClick={handleAddNewPersonSharedCartEditoMode} 
                                        >
                                            <img src="/images/icon_increment.svg" width={28} height={28}/>
                                        </div>
                                    </div>
                                </div>
                                <div style={{ flex: 1, textAlign: 'right' }}>à table</div>
                            </div>
                        </div>
                    </div>
                    {/* START FOOTER MDOAL */}
                    <div class='modal-footer'>
                        <div className='container-fluid p-3 bg-white'
                            style={{
                                marginTop: "10px",
                                borderRadius: "5px"
                            }}
                        >
                            <div>
                                <ul class="list-group list-group-flush">
                                    <li class="list-item"
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            padding: "14px",
                                            margin: 0
                                        }}
                                    >
                                        <p style={{
                                            fontSize: "15px",
                                            fontFamily: "Poppins",
                                            margin: 0,
                                            fontWeight: 600
                                        }}
                                        >
                                            Votre part
                                        </p>
                                        <p style={{
                                            fontSize: "15px",
                                            fontFamily: "Poppins",
                                            margin: 0,
                                            fontWeight: 600
                                        }}
                                        >
                                            {/* check if total pay or numberPerson === 0 prevent show NaN value */}
                                            {`${totalToPay === 0 ? "00.00" : formatPrice((totalToPay / numberPersons) * numberPersonsForPay)} Dhs`}
                                        </p>
                                    </li>
                                </ul>
                                {/* SHow Message Data not Updated */}
                                {
                                    showMessageNotUpdateData && <div 
                                        className='alert alert-secondary'
                                        onClick={() => setShowMessageNotUpdateData(false)}
                                    >
                                        {showMessageNotUpdateData} <span style={{cursor: 'pointer'}} onClick={() => setIsRefreshData(true)}><u>actualiser !</u></span>
                                    </div>
                                }
                                {/*  */}
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'center'
                                }}>
                                    <button
                                        className="btn btn-lg mt-1"
                                        type="button"
                                        style={{
                                            borderColor: "white",
                                            background:"linear-gradient(to right, #FCC546, #FDD681)",
                                            color: '#1E1E1E',
                                            fontFamily: "Poppins",
                                            fontSize: "14px",
                                            fontWeight: "400",
                                            borderRadius: '20px',
                                            paddingLeft: '33px',
                                            paddingRight: '33px'
                                        }}
                                    onClick={handleConfirmSplittedAmountToPay}
                                    disabled={ordersDataByActiveTable.length === 0}
                                    >
                                        Confirmer
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* END FOOTER MDOAL */}
                </div>
            </div>
        </div>
    )
}
