import { API_URL } from "../../../env"

export const getSuggestionsProducts = async () => {
    return fetch(`${API_URL}/api-bo/suggestions/always-suggested/show`)
    .then((res) => res.json())
}

export const getSuggestionsWithTheirCategoriesAndProducts = async (idSuggestion) => {
    return fetch(`${API_URL}/api-bo/suggestions/show-suggestion-categories-products/${idSuggestion}`)
    .then((res) => res.json())
}
