import { API_URL } from "../../../env"

export const getActiveMenuByDateTimeWithCategoriesByIdService = async (bodyObj) => {
    
    return fetch(`${API_URL}/api-bo/menus/show-by-date-time/`,{
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(bodyObj)
    })
    .then((res) => res.json())
    .then((data) => data.activeMenuWithCategories)
}

export const getProductsActiveMenuByCategoryService = async (id_menu, id_category) => {

    const bodyData = {
      id_menu: id_menu,
      id_category: id_category
    }
    return fetch(`${API_URL}/api-bo/menus/show-products-menu-bycategory`, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(bodyData)
    })
      .then((res) => res.json())
      .then((data) => data.productsOfMenu)
}

export const getMostPopularProductsByActiveMenuService = async (id_menu) => {
    return fetch(`${API_URL}/api-bo/menus/show-most-popular-by-menu/${id_menu}`)
      .then((res) => res.json())
      .then((data) => data.mostPopularProductsOfActiveMenu)
}