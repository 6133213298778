import React from 'react'
import GenericListCategories from '../common/components/GenericListCategories'
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';

export default function CategoriesList({
    categories,
    activeMenu
}) {
  return (
    <div className='container-fluid bg-white'>
        <h3 style={{
                fontFamily:"Poppins",
                fontSize: '18px',   
        }}>
            Categories
        </h3>
        <div>
            <Swiper
                spaceBetween={5}
                slidesPerView={3}
                // onSlideChange={() => console.log('slide change')}
                // onSwiper={(swiper) => console.log(swiper)}
            >
                {
                    categories && categories.map((item, key) => {
                        return (
                            <SwiperSlide>
                                <GenericListCategories
                                    id={item.id}
                                    image={item.image}
                                    categoryName={item.name}
                                    idActiveMenu={activeMenu.id_menu}
                                />
                            </SwiperSlide>
                        )
                    })
                 }      
            </Swiper>
           
        </div>  
    </div>
  )
}
