import { configureStore } from '@reduxjs/toolkit';
import cartReducer from './cartSlice';
import operationReducer from './operationSlice'

const store = configureStore({
    reducer: {
        cart: cartReducer,
        operation: operationReducer
    }
});

export default store;