import React, { useEffect, useState } from 'react'
import Header from '../common/components/Header'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid';
import SplitEqualPaymentModal from './SplitEqualPaymentModal';
import { addSplitNumberOfPersonsService, createClientAndaddSharedProductsToCartService, getCartSharedInfosByTableService, getCartSharedSplitItemsByTableService } from '../common/apiServices/cart/cart.service';
import { addOperationInfos } from '../redux/operationSlice';
import { countAllTotals, formatPrice, getAllProductsFromAllOrdersByTable, isGeneratedClientSessionId, showTotalPlusProductSuppsAndSuggsAndPacks } from '../common/helpers/utils';
import { createClientService } from './../common/apiServices/clients/client.service';
import SharedOptionsModal from './SharedOptionsModal';
import { addPersonNumberToAllSplitOrdersByTableService, getOrdersByTableService } from '../common/apiServices/orders/order.service';

export default function Payment() {
  // const { products } = useSelector((state) => state.cart.cartItems);
  const {id_table, num_table, type_shared_payment, type_payment_table} = useSelector((state) => state?.operation?.operationInfos?.tableInfos)
  const id_client = useSelector((state) => state?.operation?.operationInfos?.clientInfos?.id_client )

  const navigate = useNavigate();
  const dispatch = useDispatch();

  // START SHARE PAYMENT LOGIC
  // handle popup logic
  const [showModalOptions, setShowModalOptions] = useState(false);

  // handle mocustom persons number
  const [customPersonsNumber, setCustomPersonsNumber] = useState('');
  const [showCustomPersonsNumberModal, setShowCustomPersonsNumberModal] = useState(false);
  const [showSplitPaymentModal, setShowSplitPaymentModal] = useState(false);

  // handle custom pourboire price value
  const handleChangeCustomPersonsNumber = (numValue) => {
    setCustomPersonsNumber((prev) => prev.concat(numValue))
  }

  const handleRemoveCustomPersonsNumber = () => {
    setCustomPersonsNumber((currentText) => {
      return currentText.slice(0, currentText.length - 1)
    });

  }

  // handle submit add persons
  const handleSubmitCustomPersonsNumber = () => {
    // set max number of persons
    setCustomPersonsNumber(customPersonsNumber);

    // call api to insert number of person in shared_cart as step of selectProdToPay in sharedByChoice
    const bodyData = {
      // numberPersons, id_client_inserting, id_table, ordersArr
      numberPersons: customPersonsNumber,
      id_client_inserting: id_client,
      id_table: id_table,
      ordersArr: ordersDataByTable.map((item) => item.id_order)
    }
  
    addPersonNumberToAllSplitOrdersByTableService(bodyData)
      // store numberPerson in localstorage&store
      .then((data) => {
        let storageOperationsData = JSON.parse(localStorage.getItem('operationInfos')) || {};
        storageOperationsData = {
          ...storageOperationsData,
          tableInfos: {
            ...storageOperationsData.tableInfos,
            numberOfPersons: data?.numberOfPersons,
            client_id_inserting: data?.client_id_inserting,
            type_payment_table: "SHARED",
            type_shared_payment: "SPLIT",
          },
        };
        localStorage.setItem('operationInfos', JSON.stringify(storageOperationsData));
        dispatch(addOperationInfos(storageOperationsData))

        // close popup and open splitModal
        setShowCustomPersonsNumberModal(false);
        setShowSplitPaymentModal(true)
      })
      .catch((err) => console.log(err))

  }

  useEffect(() => {
    // allow closing modal when click outside
    const handleOutsideClick = (e) => {
      if ((showModalOptions === true || showCustomPersonsNumberModal === true) && e.target.classList.contains('modal')) {
        setShowModalOptions(false);
        setShowCustomPersonsNumberModal(false);
      }
    };

    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [showModalOptions, showCustomPersonsNumberModal]);
  // END LOGIC SHARE PAYMENT LOGIC

  const handleOwnPaymentTableCart = () => {
    // set in Store type_payment_table & create client_uuid & store response generatedUUId in store
    let storageOperations = JSON.parse(localStorage.getItem('operationInfos')) || {};
    storageOperations = {
      ...storageOperations,
      tableInfos: {
        ...storageOperations.tableInfos,
        type_payment_table: "OWN",
        type_shared_payment: null,
      }
    };

    localStorage.setItem('operationInfos', JSON.stringify(storageOperations));
    dispatch(addOperationInfos(storageOperations))

    // create client only if not exist
   if(!id_client){
     // create client uuid()
     const bodyData = {
      client_unique_value: uuidv4(),
      id_table: id_table,
    }

    // call api service
    createClientService(bodyData)
      .then((data) => {
        // store from response the client_unique_value in localStorage and redux
        let storageOperationsData = JSON.parse(localStorage.getItem('operationInfos')) || {};
        storageOperationsData = {
          ...storageOperationsData,
          clientInfos: {
            ...storageOperationsData.clientInfos,
            id_client: data?.client_session_infos?.id_client,
            client_session_id: data?.client_session_infos?.client_unique_value
          }
        };
        localStorage.setItem('operationInfos', JSON.stringify(storageOperationsData));
        dispatch(addOperationInfos(storageOperationsData))

        // redirect to 'selected-cart-items-to-pay'
        navigate('/payment-process')
      })
      .catch((err) => console.log(err))
   }else{
     // redirect to 'selected-cart-items-to-pay'
     navigate('/payment-process')
   }
   
  }

  const handleSharedPaymentTableCart = () => {
    setShowModalOptions(true)
  }

  // state checkout orders products by table
  const [ordersDataByTable, setOrdersDataByTable] = useState([])
  // add useEffect 2 to handle Checkout products by table api
  useEffect(() => {
    // call api get all products od all orders by table where not 'free'
    getOrdersByTableService(id_table)
    .then((orders) => {
      setOrdersDataByTable(orders);
    })
    .catch((err) => console.log(err))
  },[showModalOptions, showCustomPersonsNumberModal])

  return (
    <div style={{ backgroundColor: '#f7f6fa' }}>
      <Header redirectTo="/" />
      {/* img payment page */}
      <div className='container-fluid bg-white img-payment '
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "10px",
          paddingTop: "35px"
        }}
      >
        <img src="/images/payment_img.svg"
          style={{
            maxWidth: '100%',  // Make the image take up the full width of its container
            height: 'auto',    // Maintain the image's aspect ratio
            display: 'block',  // Remove any default inline styling that may affect the image
            margin: 'auto',    // Center the image horizontally within its container
          }}
          alt="img-payment"
        />
      </div>
      <div className='container-fluid text text-center bg-white'
        style={{
          paddingTop: "20px"
        }}
      >
        <h3 style={{ fontSize: '23px', textAlign: "center", margin: 0 }}>
          Table #{num_table && num_table}
          <img src="/images/quotes.svg" style={{ marginBottom: "35px" }} alt="" />
        </h3>
      </div>
      {/* menu redirection section */}
      <div className='container-fluid bg-white pt-2 pb-4'>
        <div
          className="container"
          style={{
            height: "50px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            borderRadius: "5px",
            boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
            cursor: "pointer",
          }}
          onClick={() => navigate('/food-menu')}
        >
          <p
            style={{
              fontSize: "15px",
              fontFamily: "Poppins",
              margin: 0
            }}
          >
            Voir le menu
          </p>
          <img src="images/right-arrow.svg" alt="" />
        </div>
      </div>
      {/* section show total to pay */}
      <div
        className='container-fluid listItem_seeMenu bg-white pt-3'
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: 'center',
          padding: "12px"
        }}
      >
        <p
          style={{
            fontFamily: "Poppins",
            fontSize: "20px",
            fontWeight: "900px",
            lineHeight: "32px",
            background: "linear-gradient(to right, #FCC546, #FDD681)",
            WebkitBackgroundClip: 'text',
            color: 'transparent',
            display: 'inline-block',
            WebkitTextFillColor: 'transparent', // For Safari
            margin: 0,
          }}
        >
          <b>Payez votre addition</b>
        </p>
        <p
          style={{
            fontSize: "19px",
            fontWeight: "900px",
            lineHeight: "32px",
            fontFamily: "Poppins",
            background: "linear-gradient(to right, #FCC546, #FDD681)",
            WebkitBackgroundClip: 'text',
            color: 'transparent',
            display: 'inline-block',
            WebkitTextFillColor: 'transparent', // For Safari
            margin: 0,
          }}
        >
          {/* <b>{`${formatPrice(countAllTotals(products))} Dh`}</b> */}
          <b>{`${formatPrice(countAllTotals(getAllProductsFromAllOrdersByTable(ordersDataByTable)))} Dhs`}</b>
        </p>
      </div>
      <div className='bg-white p-1'>
        <div className='container'
          style={{
            height: '4px',
            width: "100%",
            marginBottom: '10px',
            background: '#00000014',
          }}
        >
        </div>
      </div>
      {/* go to table */}
      <div className='container-fluid bg-white p-2'>
        <div>
          <table class="table table-borderless">
            <thead>
            </thead>
              {
                ordersDataByTable && ordersDataByTable.length > 0 &&  ordersDataByTable.map((order) => {
                  return (
                    <tbody key={order.id}>
                     {
                      order?.products && order.products.map((item) => {
                        return (
                            <tr 
                              // class={order.client_id === id_client && "table-secondary"} key={item.id}
                            >
                              <td>
                                <div
                                  style={{
                                    width: "30px",
                                    height: "29px",
                                    borderRadius: "5px",
                                    textAlign: "center",
                                    background: '#FCC54630'
                                  }}
                                >
                                  <p
                                    style={{
                                      fontSize: "14px",
                                      fontWeight: "800px",
                                      fontFamily: "Poppins",
                                      color: "#000000",
                                      display: 'inline-block',
                                      margin: 0,
                                      lineHeight: "32px",
                                    }}
                                  >
                                    <b>{item.quantity}</b>
                                  </p>
                                </div>
                              </td>
                              <td>
                                <p
                                  style={{
                                    fontSize: "15px",
                                    fontWeight: "400px",
                                    fontFamily: "Poppins",
                                    margin: 0,
                                    lineHeight: "32px",
                                  }}
                                >
                                  {item.name}
                                </p>
                              </td>
                              <td>
                                <p style={{
                                  fontSize: "12px",
                                  fontWeight: "400px",
                                  fontFamily: "Poppins",
                                  color: "#00000073",
                                  margin: 0,
                                  lineHeight: "32px",
                                }}>
                                  {item.quantity > 1 && item.price + " Dhs"}
                                </p>
                              </td>
                              <td>
                                <p
                                  style={{
                                    fontSize: "12px",
                                    fontWeight: "500px",
                                    fontFamily: "Poppins",
                                    margin: 0,
                                    lineHeight: "32px",
                                  }}
                                >
                                  {item.quantity * item.price + ".00 Dhs"}
                                </p>
                              </td>
                              <td>
                                <span class="badge text-bg-warning" style={{ marginLeft: '7px' }}>
                                  {showTotalPlusProductSuppsAndSuggsAndPacks(item) > 0
                                    ? `+${showTotalPlusProductSuppsAndSuggsAndPacks(item)}`
                                    : null}
                                </span>
                              </td>
                            </tr>
                        )
                      })
                     }
                    </tbody>
                  )
                })
              }
            {/* </tbody> */}
          </table>
        </div>
      </div>

      {/* display buttons */}
      <div className='container-fluid bg-white p-3'>
        <div style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "10vh"
        }}
        >
          <button
            type="button" class="btn btn-light btn-lg"
            // data-bs-toggle="modal" 
            // data-bs-target="#shareModal"
            style={{
              fontSize: "14px",
              fontFamily: "Poppins",
              padding: "13px",
              background: "white",
              border: " solid black 1px",
            }}
            onClick={handleSharedPaymentTableCart}
          >
            Partager l’addition
          </button>
          <button type="button" class="btn btn-light btn-lg"
            style={{
              marginLeft: "5px",
              background:"linear-gradient(to right, #FCC546, #FDD681)",
              color: '#1E1E1E',
              border: "none",
              fontSize: "14px",
              fontFamily: "Poppins",
              padding: "13px"
            }}
            onClick={handleOwnPaymentTableCart}
          >
            Payer toute l’addition
          </button>
        </div>
      </div>

      {/* START MODAL */}
      {
        showModalOptions && (
          <SharedOptionsModal
            title="Partager l’addition"
            setShowModalOptions={setShowModalOptions}
            setShowCustomPersonsNumberModal={setShowCustomPersonsNumberModal}
            setShowSplitPaymentModal={setShowSplitPaymentModal}
            showModalOptions={showModalOptions}
          />
        )
      }
      {/* END MODAL */}

      {/* START MODAL CUSTOM INSERT NUMBERS */}
      {
        showCustomPersonsNumberModal && (
          <div
            className="modal fade show"
            // id="confirmationModal"
            tabindex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
            style={{
              display: 'block',
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
            }}
          >
            <div class="modal-dialog"
              style={{
                position: "fixed",
                bottom: -15,
                left: "49%",
                transform: "translateX(-50%)",
                width: "100%",
              }}
            >
              <div class="modal-content"
                style={{ borderColor: "none", }}
              >
                <div class="modal-header">
                  <h5 class="modal-title text tex-center" id="customPourboireModalLabel"
                    style={{
                      fontFamily: "Poppins",
                      fontSize: "22px",
                      fontWeight: "600",
                    }}
                  >Nombre de personnes attablé</h5>
                  <img
                    src="images/close-icon.svg"
                    data-bs-dismiss="modal" aria-label="Close" alt="close-modal-icon"
                    style={{ cursor: "pointer" }}
                    onClick={() => setShowCustomPersonsNumberModal(false)}
                  />
                </div>
                <div class="modal-body">
                  <div>
                    <div className="custom-number-value"
                      style={{
                        padding: "15px",
                        textAlign: "center",
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      <h3 style={{
                        color: 'black',
                        borderBottom: 'solid black 5px',
                        width: '80px',
                        paddingBottom: '5px'
                      }}
                      >
                        {`${customPersonsNumber}`}
                      </h3>

                    </div>
                  </div>
                  <div style={{ textAlign: 'center', paddingBottom: '5px' }}>
                    <button
                      class="btn btn-primary "
                      data-bs-dismiss="modal" aria-label="Close"
                      type="button"
                      style={{
                        fontSize: "15px",
                        fontFamily: "Poppins",
                        fontWeight: "400",
                        background: "white",
                        border: "solid black 1px",
                        marginTop: "8px",
                        padding: "10px",
                        borderColor: "white",
                        background:"linear-gradient(to right, #FCC546, #FDD681)",
                        color: '#1E1E1E',
                        width: "80%"
                      }}
                      onClick={handleSubmitCustomPersonsNumber}
                    >Enregistrer</button>
                  </div>
                  <div className='number-inputs mt-3'>
                    <div className='boxes_container'
                      style={{
                        backgroundColor: 'lightgray',
                        display: 'flex',
                        justifyContent: 'center',
                        flexWrap: 'wrap',
                        padding: '3px',
                        borderRadius: '5px'
                      }}
                    >
                      <div className='num_box' style={{
                        background: 'white',
                        textAlign: 'center',
                        margin: '4px',
                        width: '140px',
                        borderRadius: '10px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: '7px',
                        cursor: 'pointer'
                      }}
                        onClick={() => handleChangeCustomPersonsNumber('1')}
                      >
                        <h3>1</h3>
                      </div>
                      <div className='num_box' style={{
                        background: 'white',
                        textAlign: 'center',
                        margin: '4px',
                        width: '140px',
                        borderRadius: '10px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: '7px',
                        cursor: 'pointer'
                      }}
                        onClick={() => handleChangeCustomPersonsNumber('2')}
                      >
                        <h3>2</h3>
                      </div>
                      <div className='num_box' style={{
                        background: 'white',
                        textAlign: 'center',
                        margin: '4px',
                        width: '140px',
                        borderRadius: '10px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: '7px',
                        cursor: 'pointer'
                      }}
                        onClick={() => handleChangeCustomPersonsNumber('3')}
                      >
                        <h3>3</h3>
                      </div>
                      <div className='num_box' style={{
                        background: 'white',
                        textAlign: 'center',
                        margin: '4px',
                        width: '140px',
                        borderRadius: '10px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: '7px',
                        cursor: 'pointer'
                      }}
                        onClick={() => handleChangeCustomPersonsNumber('4')}
                      >
                        <h3>4</h3>
                      </div>
                      <div className='num_box' style={{
                        background: 'white',
                        textAlign: 'center',
                        margin: '4px',
                        width: '140px',
                        borderRadius: '10px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: '7px',
                        cursor: 'pointer'
                      }}
                        onClick={() => handleChangeCustomPersonsNumber('5')}
                      >
                        <h3>5</h3>
                      </div>
                      <div className='num_box' style={{
                        background: 'white',
                        textAlign: 'center',
                        margin: '4px',
                        width: '140px',
                        borderRadius: '10px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: '7px',
                        cursor: 'pointer'
                      }}
                        onClick={() => handleChangeCustomPersonsNumber('6')}
                      >
                        <h3>6</h3>
                      </div>
                      <div className='num_box' style={{
                        background: 'white',
                        textAlign: 'center',
                        margin: '4px',
                        width: '140px',
                        borderRadius: '10px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: '7px',
                        cursor: 'pointer'
                      }}
                        onClick={() => handleChangeCustomPersonsNumber('7')}
                      >
                        <h3>7</h3>
                      </div>
                      <div className='num_box' style={{
                        background: 'white',
                        textAlign: 'center',
                        margin: '4px',
                        width: '140px',
                        borderRadius: '10px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: '7px',
                        cursor: 'pointer'
                      }}
                        onClick={() => handleChangeCustomPersonsNumber('8')}
                      >
                        <h3>8</h3>
                      </div>
                      <div className='num_box' style={{
                        background: 'white',
                        textAlign: 'center',
                        margin: '4px',
                        width: '140px',
                        borderRadius: '10px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: '7px',
                        cursor: 'pointer'
                      }}
                        onClick={() => handleChangeCustomPersonsNumber('9')}
                      >
                        <h3>9</h3>
                      </div>
                      <div className='num_box' style={{
                        background: 'lightgray',
                        textAlign: 'center',
                        margin: '4px',
                        width: '140px',
                        borderRadius: '10px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: '7px',
                        cursor: 'pointer'
                      }}
                      // onClick={() => handleChangeCustomPersonsNumber('1')}
                      >
                        <h3></h3>
                      </div>
                      <div className='num_box' style={{
                        background: 'white',
                        textAlign: 'center',
                        margin: '4px',
                        width: '140px',
                        borderRadius: '10px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: '7px',
                        cursor: 'pointer'
                      }}
                        onClick={() => handleChangeCustomPersonsNumber('0')}
                      >
                        <h3>0</h3>
                      </div>
                      <div className='num_box' style={{
                        background: 'lightgray',
                        textAlign: 'center',
                        margin: '4px',
                        width: '140px',
                        borderRadius: '10px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: '7px',
                        cursor: 'pointer'
                      }}
                        onClick={handleRemoveCustomPersonsNumber}
                      >
                         <svg xmlns="http://www.w3.org/2000/svg" width="40" height="35" fill="currentColor" class="bi bi-backspace" viewBox="0 0 16 16">
                            <path d="M5.83 5.146a.5.5 0 0 0 0 .708L7.975 8l-2.147 2.146a.5.5 0 0 0 .707.708l2.147-2.147 2.146 2.147a.5.5 0 0 0 .707-.708L9.39 8l2.146-2.146a.5.5 0 0 0-.707-.708L8.683 7.293 6.536 5.146a.5.5 0 0 0-.707 0z"/>
                            <path d="M13.683 1a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-7.08a2 2 0 0 1-1.519-.698L.241 8.65a1 1 0 0 1 0-1.302L5.084 1.7A2 2 0 0 1 6.603 1zm-7.08 1a1 1 0 0 0-.76.35L1 8l4.844 5.65a1 1 0 0 0 .759.35h7.08a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1z"/>
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      }
      {/* END MODAL CUSTOM INSERT NUMBERS */}

      {/* STAT SPLIT EQUAL PAYMENT MODAL */}
      {
        showSplitPaymentModal && <SplitEqualPaymentModal
          title='Diviser en part égales'
          setShowSplitPaymentModal={setShowSplitPaymentModal}
        />
      }
      {/* STAT SPLIT EQUAL PAYMENT MODAL */}

    </div>
  )
}
