import { API_URL } from "../../../env"

export const getReviewSettings = async () => {
    return fetch(`${API_URL}/api/reviews/settings/show`)
    .then((res) => res.json())
    .catch((err) => err)
}

export const createReviewService = (bodyData) => {
    return fetch(`${API_URL}/api/reviews/create`, {
        method: "POST",
        headers:{
            "Content-Type": "application/json"
        },
        body: JSON.stringify(bodyData),
    })
    .then(res => res.json())
    .catch(err => err)
}