import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    operationInfos: JSON.parse(localStorage.getItem('operationInfos')) || {},
}

export const operationSlice = createSlice({
    name: 'operation',
    initialState,
    reducers: {
        addOperationInfos(state, action){
            return {
                ...state,
                operationInfos: action.payload
            };
        },
    }
});


export const { addOperationInfos } = operationSlice.actions;

export default operationSlice.reducer;