
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import ReviewStarsModal from './ReviewStarsModal';

export default function ReviewStars() {
    const receiptInfosSelector = useSelector((state) => state?.operation?.operationInfos?.receiptInfos)
    const navigate = useNavigate()
    const {isreview} = useParams()
    
    // coupon process
    const [showModalReviewStars, setShowModalReviewStars] = useState(isreview === 'yes' ? true : false);

    const handleReviewStarsModal = () => {
        setShowModalReviewStars(true)
    }

  return (
    <div style={{
        backgroundColor: '#f7f6fa'
    }}>
        <div className="section_1 m-2 bg-white px-1 py-3">
            <ul class="list-group list-group-flush">
                <li class="list-group-item"
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        padding: "14px",
                        margin: 0
                    }}
                >
                    <p style={{
                        fontSize: "15px",
                        fontFamily: "Poppins",
                        margin: 0,
                        color: '#FF7900'
                    }}
                    >
                        <b>Vous avez payé </b>
                    </p>
                    <p style={{
                        fontSize: "15px",
                        fontFamily: "Poppins",
                        margin: 0
                    }}
                    >
                        <b>{`${receiptInfosSelector && receiptInfosSelector?.paidAmount} Dhs`}</b>
                    </p>
                </li>
                {/* <li class="list-group-item"
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        padding: "14px",
                        margin: 0
                    }}
                >
                    <p style={{
                        fontSize: "15px",
                        fontFamily: "Poppins",
                        margin: 0,
                        margin: 0
                    }}
                    >
                        Restant à payer
                    </p>
                    <p style={{
                        fontSize: "15px",
                        fontFamily: "Poppins",
                        margin: 0
                    }}
                    >
                        <b>{`10002 Dhs`}</b>
                    </p>
                </li> */}
            </ul>
            <div style={{marginTop: 20, paddingLeft: 15, paddingRight: 15}}>
                <p style={{fontSize: 14, fontWeight: 500, fontFamily: 'Poppins', color: '#1B0B29'}}>
                    L’équipe du restaurant est informée de votre paiement 
                    et vous remercie pour votre visite.<br/> À très bientôt !
                </p>
            </div>
            <div style={{
                display: 'flex',
                justifyContent: 'center'
            }}>
                <button
                    className="btn btn-lg mt-1"
                    type="button"
                    style={{
                        borderColor: "white",
                        background: "linear-gradient(to right, #FCC546, #FDD681)",
                        color: '#1E1E1E',
                        fontFamily: "Poppins",
                        fontSize: "14px",
                        fontWeight: "600",
                        borderRadius: '20px',
                        paddingLeft: '20px',
                        paddingRight: '33px'
                    }}
                    onClick={() => navigate('/receipt')}
                >
                    <img src='/images/reviews/receipt_icon.svg' style={{marginRight: 8}}/>
                    Obtenir mon reçu
                </button>
            </div>
        </div>
        <div className="section_2 m-3 bg-white p-3 ">
            <div style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
            }}>
                <h4 style={{
                    fontSize: 18,
                    fontWeight: 600,
                    fontFamily: 'Poppins'
                }}>En payant via Tablati, Vous avez <br/> gagné  <span style={{color:'#FCC546'}}>2 000 Miamus </span>! BRAVO!</h4>
                {/* <img src='' /> */}
                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="orange" class="bi bi-trophy-fill" viewBox="0 0 16 16">
                    <path d="M2.5.5A.5.5 0 0 1 3 0h10a.5.5 0 0 1 .5.5q0 .807-.034 1.536a3 3 0 1 1-1.133 5.89c-.79 1.865-1.878 2.777-2.833 3.011v2.173l1.425.356c.194.048.377.135.537.255L13.3 15.1a.5.5 0 0 1-.3.9H3a.5.5 0 0 1-.3-.9l1.838-1.379c.16-.12.343-.207.537-.255L6.5 13.11v-2.173c-.955-.234-2.043-1.146-2.833-3.012a3 3 0 1 1-1.132-5.89A33 33 0 0 1 2.5.5m.099 2.54a2 2 0 0 0 .72 3.935c-.333-1.05-.588-2.346-.72-3.935m10.083 3.935a2 2 0 0 0 .72-3.935c-.133 1.59-.388 2.885-.72 3.935"/>
                </svg>
            </div>
            <div className="my-2">
                <p style={{
                    fontSize: 13,
                    fontWeight: 500,
                    color: '#8B8B8B',
                    fontFamily: 'Poppins',
                    fontStyle: 'italic'
                }}>
                    Vous pouvez utilisez vos Miamus chez tous nos 
                    restaurant partenaire. 
                </p>
            </div>
            <div>
                <form>
                    <div class="mb-3">
                        <label for="exampleFormControlInput1" class="form-label" style={{
                            fontSize: 13,
                            fontWeight: 500,
                            color: '#1B0B29',
                            fontFamily: 'Poppins',
                        }}>Entrez votre e-mail et savourez la récompense !</label>
                        <input type="email" class="form-control" id="exampleFormControlInput1"/>
                    </div>
                </form>
            </div>
        </div>
          {/* START MODAL */}
          {
            showModalReviewStars === true && (
                <ReviewStarsModal
                    setShowModalReviewStars={setShowModalReviewStars}
                />
            )
          }
        {/* END MODAL */}
    </div>
  )
}
