import React from 'react'
import { useNavigate } from 'react-router-dom';

export default function OrderInfo() {

  const navigate = useNavigate();

  return (
    <div style={{
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center'
    }}>
        <img src="/images/food_order.gif" width={"92%"} height={360}/>
        <div>
            <h3 style={{fontSize: 25, fontWeight: 600, fontFamily: 'Poppins', textAlign: 'center'}}>Génial ! Votre commande a été envoyé en cuisine </h3>
            <p style={{fontSize: 15, fontWeight: 500,fontFamily: 'Poppins', textAlign: 'center'}}>
                Nos chefs sont impatients d'éveiller vos papilles. Votre repas sera prêt dans les plus brefs délais.
            </p>
        </div>
        <button
            style={{
                paddingLeft: "130px",
                paddingRight: "130px",
                paddingTop: '15px',
                paddingBottom: '15px',
                borderColor: "white",
                background: "linear-gradient(to right, #FCC546, #FDD681)",
                color: '#1E1E1E',
                fontSize: 18,
                fontWeight: 500,
                fontFamily: 'Poppins',
            }}
            type="button"
            className="btn btn-primary btn-lg"
            onClick={() => navigate('/')}
        >
            Confirmer
        </button>
    </div>
  )
}
