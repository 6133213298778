import { API_URL } from "../../../env"

export const getPacksByProductService = (id) => {
    return fetch(`${API_URL}/api-bo/packs/show-packs-byproduct?id_product=${id}`)
    .then((res) => res.json())
}

export const getPackWithCategoriesAndProductsService = async (id) => {
    return fetch(`${API_URL}/api-bo/packs/show-pack-categories-products/${id}`)
    .then((res) => res.json())
}