import React from 'react'
import { useSelector } from 'react-redux'
import { Link, useLocation, useNavigate } from 'react-router-dom';

export default function Header({
  redirectTo
}) {  
  const {count} = useSelector((state) => state.cart.cartItems);
  const navigate = useNavigate();
  const location = useLocation();
  const isCartRoute = location.pathname === '/cart';
  const isPaymentRoute = location.pathname === '/payment';
  const isPaymentProcessRoute = location.pathname === '/payment-process';
  const isPourboireRoute = location.pathname === '/selected-products-topay'

  return (
          <div className="container-fluid bg-white" 
              style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems:'center',
                  height: '80px',
                  position: 'sticky',
                  top: '0',
                  zIndex: 1000,
              }}
          >
            <div style={{ flex: 1, textAlign: 'left' }}>
              {
                (isPaymentRoute || isPaymentProcessRoute || isPourboireRoute) && (
                    <Link to={redirectTo}>
                      <img src="/images/icon-arrow-left.svg" alt="icon-arrow-left" />
                    </Link> 
                )
              }
            </div>
            <div 
              style={{
                cursor: 'pointer',
                flex: '1',
                textAlign: 'center',
              }}
            >
              <img 
                src="/images/tablati_logo.png" 
                alt="tablati-logo"
                width={100}
                height={37}
                onClick={() => navigate('/')}
              />
            </div>
            <div style={{ flex: 1, textAlign: 'right' }}>
          {
            !isCartRoute && !isPaymentRoute && !isPaymentProcessRoute && (
              <>
                <img 
                    src="/images/cart_icon.svg" 
                    style = {{
                        border: 'solid white 1px',
                        borderRadius: '50%',
                        backgroundColor: '#FCC54630',
                        padding:"10px",
                        cursor:"pointer",
                    }} 
                    onClick={() => navigate('/cart')}
                    alt="cart-icon"
                />
                <div
                  style={{
                    height:"20px",
                    width:"20px",
                    position: 'absolute',
                    top: '8px',
                    right: '6px',
                    backgroundColor: count > 0 ? '#FF9500' : 'none',
                    color: 'white',
                    borderRadius: '50%',
                    padding: '5px',
                    textAlign:"center",
                    display:"flex",
                    justifyContent:"center",
                    alignItems:"center"
                }}
                >
                  {count > 0 && count}
                </div>
              </>
              
              )
            }
      </div>
    </div>
  )
}
