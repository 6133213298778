import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom"
import { getPacksByProductService } from "../../common/apiServices/packs/pack.service";
import PackDetailsModal from "./PackDetailsModal";
import { getSupplementsByProductService } from "../../common/apiServices/supplements/supplement.service";
import { getSuggestionsWithTheirCategoriesAndProducts } from "../../common/apiServices/suggestions/suggestion.service";


export default function PacksProductSection({
    // id_product,
    selectedProduct,
    handleCheckedPacksProp
}) {
    const naviagte = useNavigate();

    const [packsProduct, setPacksProducts] = useState([])
    const [ShowPackDetailsModal, setShowPackDetailsModal] = useState(false)
    const [selectedPackToView, setSelectedPackToViews] = useState(null)
    const [supplementsByProductList, setSupplementsByProductList] = useState([])
    const [suggestionsProduct, setSuggestionsProduct] = useState([])

    //
    const handleSelectedViewPackDetails = (item) => {
        // console.log(item)
        setSelectedPackToViews(item)
        setShowPackDetailsModal(true)
    }

    useEffect(() => {
        getPacksByProductService(selectedProduct?.id)
            .then((data) => setPacksProducts(data.packsByProductList))
            .catch((err) => console.log(err))
        
        // these 2 apis called just to handle "separation Line"
        getSupplementsByProductService(selectedProduct?.id)
        .then((data) => setSupplementsByProductList(data.supplementsProduct))
        .catch((err) => console.log(err))

        getSuggestionsWithTheirCategoriesAndProducts(selectedProduct.id_category)
            .then((data) => setSuggestionsProduct(data.suggestionsWithCategoriesAndProducts))
            .catch((err) => console.log(err))
    }, [selectedProduct?.id])

    return (
        <div>
            <div className='packs_section'>
                {
                    packsProduct && (
                        <>
                            <h5 style={{ fontSize: 21, fontWeight: 600 }}>Seul ou en Menu</h5>
                            <div style={{
                                display: "flex",
                                justifyContent: "space-between"
                            }}>
                                <div style={{ cursor: "pointer" }}>
                                    <h4 style={{ fontSize: 19, fontWeight: 500 }}>Seul</h4>
                                    <p style={{ fontSize: 14, fontWeight: 400 }}>+ 00 DH</p>
                                </div>
                                <div class="form-check">
                                    <input
                                        class="form-check-input packChecks"
                                        type="radio"
                                        name="packCheckOpion"
                                        id="flexRadioDefault1"
                                        defaultChecked
                                        style={{
                                            border: 'solid #FF9500 2px',
                                            cursor: "pointer"
                                        }}
                                    />
                                </div>
                            </div>
                        </>
                    )
                }
                {/* END Seul Menu & START packs  */}
                {
                    packsProduct && packsProduct.map((item) => {
                        return (
                            <>
                                <div style={{
                                    display: "flex",
                                    justifyContent: "space-between"
                                }}>
                                    <div
                                        style={{ cursor: "pointer" }}
                                        onClick={() => handleSelectedViewPackDetails(item)}
                                    >
                                        <h4 style={{ fontSize: 19, fontWeight: 500 }}>En {item.name} </h4>
                                        {/* HERE CALL API TO GET TOTAL OF ALL PACKSPRDS */}
                                        <p style={{ fontSize: 14, fontWeight: 400 }}>+ 25 DH</p>
                                    </div>
                                    <div class="form-check">
                                        <input
                                            class="form-check-input packChecks"
                                            type="radio"
                                            name="packCheckOpion"
                                            id="flexRadioDefault1"
                                            // checked
                                            style={{
                                                border: 'solid #FF9500 2px',
                                                cursor: "pointer",
                                                // marginBottom: "10px"
                                            }}
                                        />
                                        <img
                                            src="/images/arrow_right_pack.svg" alt="arrow_icon"
                                            style={{ cursor: "pointer", width: "11px", marginBottom: "4px"}}
                                            onClick={() => handleSelectedViewPackDetails(item)}
                                        />
                                    </div>
                                </div>
                            </>
                        )
                    })
                }
            </div>
            {packsProduct && (supplementsByProductList || suggestionsProduct) && <hr />}
            {/* START MODAL PACKDETAILS */}
            {
                ShowPackDetailsModal && 
                    <PackDetailsModal
                        id_pack={selectedPackToView?.id}
                        setShowPackDetailsModal={setShowPackDetailsModal}
                        handleCheckedPacksProp={handleCheckedPacksProp}
                  />
            }
            {/* END MODAL PACKDETAILS */}
        </div>
    )
}
