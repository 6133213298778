import { API_URL } from "../../env"

export const getCategories = () => {
    return fetch(`${API_URL}/api/categories/showall`)
    .then((res) => res.json())
    .then((data) => data.categories)
}

export const getCategoryById = (id) => {
    return fetch(`${API_URL}/api/categories/show/${id}`)
    .then((res) => res.json())
    .then((data) => data.category)
}