import { useEffect, useState } from "react"
import { getSuggestionsWithTheirCategoriesAndProducts } from "../../common/apiServices/suggestions/suggestion.service"
import { getCategoryById } from "../../common/apiServices/categoryApiCall";

export default function SuggestionsProduct({
    selectedProduct,
    handleCheckedSuggestionsProp
}) {

    const [suggestionsProduct, setSuggestionsProduct] = useState([])
    const [checkedSuggestions, setCheckedSuggestions] = useState([]);

    const handleChangeCheckedSuggestions = (prodItem) => {
        let copyCheckedSuggestions;
        const foundSuggestion = checkedSuggestions.find((sugg) => sugg.idProduct === prodItem.idProduct)
        if(foundSuggestion){
            copyCheckedSuggestions = checkedSuggestions.filter((sugg) => sugg.idProduct !== prodItem.idProduct);
        }else{
            copyCheckedSuggestions = [...checkedSuggestions, prodItem];
        }
        setCheckedSuggestions(copyCheckedSuggestions)
        handleCheckedSuggestionsProp(copyCheckedSuggestions)
    }

    useEffect(() => {
        // get catgoryData of selected product
        getCategoryById(selectedProduct?.id_category)
        .then((category) => {
            // call api to get suggestionData by Id_suggestion from category
            getSuggestionsWithTheirCategoriesAndProducts(category?.suggestion_id)
            .then((data) => {
                setSuggestionsProduct(data.suggestionsWithCategoriesAndProducts)
            })
            .catch((err) => console.log(err))
        })
        .catch((err) => console.log(err))
        
    }, [selectedProduct])

    return (
        <div>
            <div className='suggestions-section'>
                {suggestionsProduct && <h5 style={{ fontSize: 21, fontWeight: 600 }}>Suggestions</h5>}
                <div className="suggestion_list mt-4">
                    {
                        suggestionsProduct && suggestionsProduct?.categories?.map((item) => {
                            return (
                                <div className="pack_item_details mb-2">
                                    <h5 style={{ fontSize: 19, fontWeight: 600 }}>{item?.name_category}</h5>
                                    {/* loop on products here */}
                                    {
                                        item?.products.length > 0 && item?.products?.map((prod) => {
                                            return (
                                                <div style={{
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                    // alignItems: "center",
                                                    padding: "7px"
                                                }}>
                                                    <div>
                                                        <h4 style={{ fontSize: 19, fontWeight: 500 }}>{prod?.productName}</h4>
                                                        <p style={{ fontSize: 14, fontWeight: 400 }}>{prod?.productPrice} DH</p>
                                                    </div>
                                                    <div class="form-check">
                                                        <input
                                                            class="form-check-input suggChecks"
                                                            type="checkbox"
                                                            id="flexCheckChecked"
                                                            // value={item}
                                                            onChange={() => handleChangeCheckedSuggestions(prod)}
                                                            style={{
                                                                width: "19px",
                                                                height: "19px",
                                                                cursor: "pointer",
                                                                border: 'solid #FF9500 2px'
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}
