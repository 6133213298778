import React, { useEffect } from 'react'
import Header from '../common/components/Header'
import SubHeader from '../common/components/SubHeader'
import { useLocation } from 'react-router-dom';
import { useState } from 'react';
import GenericListProducts from './../common/components/GenericListProducts';
import { lineStyle } from './MostPopularProducts';
import { getCategoryById } from '../common/apiServices/categoryApiCall';
import { getProductsActiveMenuByCategoryService } from '../common/apiServices/menu/menu.service';

export default function ProductsList() {
    // get categoryID URL-Query
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const id_category = queryParams.get('category');
    const idActiveMenu = queryParams.get('id_menu');

    const [filterProducts, setFilterProducts] = useState([]);
    const [currentCategory, setCurrentCategory] = useState({});

    useEffect(() => {
        getCategoryById(Number(id_category))
        .then((categ) => setCurrentCategory(categ))
        
        // get products of Active Menus By category
        getProductsActiveMenuByCategoryService(idActiveMenu, id_category)
        .then((prods) => setFilterProducts(prods))
        .catch(err => console.log(err))
        
    }, [id_category])

    return (
        <div style={{ backgroundColor: '#f7f6fa' }}>
            <Header />
            <SubHeader redirectTo="/food-menu" />
            <div className='container-fluid bg-white'>
                <div style={{ paddingLeft: "10px" }}>
                    <h3 style={{
                        fontFamily: "Poppins",
                        fontSize: '22px',
                    }}>
                        {currentCategory && currentCategory.name}
                    </h3>
                </div>
                <div style={lineStyle}></div>
                {filterProducts && filterProducts.map((item) => {
                    return (
                        <>
                            <GenericListProducts product={item} />
                        </>
                    )
                })}
            </div>
        </div>
    )
}
