import React, { useEffect, useState } from 'react';
import { createReviewService, getReviewSettings } from '../common/apiServices/reviews/review.service';

export default function ReviewStarsModal({
    setShowModalReviewStars,
}) {

    const [maxStarsNumber, setMaxStarsNumber] = useState(0);
    const [selectedStars, setSelectedStars] = useState([]);
    const [selectedServiceStars, setSelectedServiceStars] = useState([]);
    const [selectedVibeStars, setSelectedVibeStars] = useState([]);
    const [selectedDishStars, setSelectedDishStars] = useState([]);
    const [selectedReportStars, setSelectedReportStars] = useState([]);
    const [isShowNextReviewSection, setIsShowNextReviewSection] = useState(false);

    useEffect(() => {
        getReviewSettings()
            .then((data) => {
                const { stars_number } = data.settings[0];
                setMaxStarsNumber(stars_number);
                // Initialize all stars as unselected
                setSelectedStars(Array(stars_number).fill(false)); 
                setSelectedServiceStars(Array(stars_number).fill(false));
                setSelectedVibeStars(Array(stars_number).fill(false));
                setSelectedDishStars(Array(stars_number).fill(false));
                setSelectedReportStars(Array(stars_number).fill(false));
            })
            .catch((err) => console.log(err));
    }, []);

    const handleStarClick = (index) => {
        setSelectedStars(prevSelectedStars => {
            const newSelectedStars = prevSelectedStars.map((_, i) => i <= index); // Fill all stars up to and including the clicked one
            // If the clicked star is already selected, we unselect it and all stars after it
            if (prevSelectedStars[index]) {
                return prevSelectedStars.map((_, i) => i < index); // Unselect the clicked star and any after it
            }
            return newSelectedStars;
        });
        setIsShowNextReviewSection(true)
    };
    const handleServiceStarClick = (index) => {
        setSelectedServiceStars(prevSelectedServiceStars => {
            const newSelectedServiceStars = prevSelectedServiceStars.map((_, i) => i <= index); // Fill all stars up to and including the clicked one
            // If the clicked star is already selected, we unselect it and all stars after it
            if (prevSelectedServiceStars[index]) {
                return prevSelectedServiceStars.map((_, i) => i < index); // Unselect the clicked star and any after it
            }
            return newSelectedServiceStars;
        });
    };
    const handleVibeStarClick = (index) => {
        setSelectedVibeStars(prevSelectedVibeStars => {
            const newSelectedVibeStars = prevSelectedVibeStars.map((_, i) => i <= index); // Fill all stars up to and including the clicked one
            // If the clicked star is already selected, we unselect it and all stars after it
            if (prevSelectedVibeStars[index]) {
                return prevSelectedVibeStars.map((_, i) => i < index); // Unselect the clicked star and any after it
            }
            return newSelectedVibeStars;
        });
    };
    const handleDishStarClick = (index) => {
        setSelectedDishStars(prevSelectedDishStars => {
            const newSelectedDishStars = prevSelectedDishStars.map((_, i) => i <= index); // Fill all stars up to and including the clicked one
            // If the clicked star is already selected, we unselect it and all stars after it
            if (prevSelectedDishStars[index]) {
                return prevSelectedDishStars.map((_, i) => i < index); // Unselect the clicked star and any after it
            }
            return newSelectedDishStars;
        });
    };
    const handleReportStarClick = (index) => {
        setSelectedReportStars(prevSelectedReportStars => {
            const newSelectedReportStars = prevSelectedReportStars.map((_, i) => i <= index); // Fill all stars up to and including the clicked one
            // If the clicked star is already selected, we unselect it and all stars after it
            if (prevSelectedReportStars[index]) {
                return prevSelectedReportStars.map((_, i) => i < index); // Unselect the clicked star and any after it
            }
            return newSelectedReportStars;
        });
    };

    // handle messsage and email review
    const [messageReview, setMessageReview] = useState('');
    const [emailReview, setEmailReview] = useState('');
    const [messageRequiredFieldsReview, setMessageRequiredFieldsReview] = useState('');
    
    const handleChangeMessageReview = (event) => {
        setMessageReview(event.target.value);
    }
    const handleChangeEmailReview = (event) => {
        setEmailReview(event.target.value);
    }
    
    const handleSubmitAddReview = (event) => {
        event.preventDefault();
        if(emailReview === ''){
            setMessageRequiredFieldsReview('Email est obligatoire !');
        }else{
            const bodyObj = {
                general_stars: selectedStars.filter(item => item === true).length,
                service_stars: selectedServiceStars.filter(item => item === true).length,
                vibe_stars: selectedVibeStars.filter(item => item === true).length,
                dish_stars: selectedDishStars.filter(item => item === true).length,
                report_stars: selectedReportStars.filter(item => item === true).length,
                content: messageReview,
                email: emailReview,
            };
            createReviewService(bodyObj)
                .then(data => {
                    if(data.code === 1){
                        setShowModalReviewStars(false)
                    }
                })
                .catch(err => console.log(err))
        }
    }
    return (
        <div
            className="modal fade show"
            id="shareModal"
            tabindex="-1"
            aria-labelledby="shareModalLabel"
            aria-hidden="true"
            style={{
                display: 'block',
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
            }}
        >
            <div className="modal-dialog"
                style={{
                    // position: isShowNextReviewSection === false ? "fixed" : '',
                    // bottom: isShowNextReviewSection === false ? -15 : '',
                    // left: isShowNextReviewSection === false ? "49%": '',
                    // transform: isShowNextReviewSection === false ? "translateX(-50%)": '',
                    width: "100%",
                }}
            >
                <div className="modal-content" style={{ borderColor: "none", }}>
                    <div className="modal-header">
                        <div></div>
                        <img
                            src="/images/close-icon.svg"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            alt="close-modal-icon"
                            style={{ cursor: "pointer" }}
                            onClick={() => setShowModalReviewStars(false)}
                        />
                    </div>
                    <div className="modal-body">
                        <div>
                            <div style={{textAlign: 'center'}}>
                             <img src="/images/reviews/restau_img.png" />
                            </div>
                            <p style={{
                                fontSize: 16,
                                fontWeight: 600,
                                fontFamily: 'Poppins',
                                textAlign: 'center',
                                marginTop: 10
                            }}>
                                Sur une échelle de 1 à 5, comment évaluez-vous votre expérience aujourd’hui ?<br />
                                <span style={{ color: '#FF7900' }}>(et gagnez 1 000 MIAMUS !)</span>
                            </p>
                            <div style={{
                                display: 'flex',
                                justifyContent: 'space-evenly',
                                marginBottom: 15
                            }}>
                                {maxStarsNumber && Array.from({ length: maxStarsNumber }, (_, index) => (
                                    <i
                                        key={index}
                                        className={`bi bi-star${selectedStars[index] ? '-fill' : ''}`}
                                        style={{
                                            fontSize: 34,
                                            cursor: "pointer",
                                            color: selectedStars[index] ? '#FDCC0D' : 'lightgray',
                                            padding: '5px',
                                            borderRadius: '50%',
                                        }}
                                        onClick={() => handleStarClick(index)}
                                    ></i>
                                ))}
                            </div>
                            {/* Second Review Section */}
                            {isShowNextReviewSection && (
                                <div>
                                    <p style={{
                                        fontSize: 15,
                                        fontWeight: 500,
                                        fontFamily: 'Poppins',
                                        textDecoration: 'underline'
                                    }}>Pouvez-vous nous en dire plus ?</p>
                                    <div className='service-stars'>
                                        <p style={{
                                            fontSize: 15,
                                            fontWeight: 500,
                                            fontFamily: 'Poppins',
                                        }}>Service</p>
                                        <div>
                                            {maxStarsNumber && Array.from({ length: maxStarsNumber }, (_, index) => (
                                                <i
                                                    key={index}
                                                    className={`bi bi-star${selectedServiceStars[index] ? '-fill' : ''}`}
                                                    style={{
                                                        fontSize: 34,
                                                        cursor: "pointer",
                                                        color: selectedServiceStars[index] ? '#FDCC0D' : 'lightgray',
                                                        padding: '3px',
                                                        borderRadius: '50%',
                                                        marginRight: 30
                                                    }}
                                                    onClick={() => handleServiceStarClick(index)}
                                                ></i>
                                            ))}
                                        </div>
                                    </div>
                                    <div className='service-stars'>
                                        <p style={{
                                            fontSize: 15,
                                            fontWeight: 500,
                                            fontFamily: 'Poppins',
                                        }}>Ambiance</p>
                                        <div>
                                            {maxStarsNumber && Array.from({ length: maxStarsNumber }, (_, index) => (
                                                <i
                                                    key={index}
                                                    className={`bi bi-star${selectedVibeStars[index] ? '-fill' : ''}`}
                                                    style={{
                                                        fontSize: 34,
                                                        cursor: "pointer",
                                                        color: selectedVibeStars[index] ? '#FDCC0D' : 'lightgray',
                                                        padding: '3px',
                                                        borderRadius: '50%',
                                                        marginRight: 30
                                                    }}
                                                    onClick={() => handleVibeStarClick(index)}
                                                ></i>
                                            ))}
                                        </div>
                                    </div>
                                    <div className='service-stars'>
                                        <p style={{
                                            fontSize: 15,
                                            fontWeight: 500,
                                            fontFamily: 'Poppins',
                                        }}>Plats et boissons</p>
                                        <div>
                                            {maxStarsNumber && Array.from({ length: maxStarsNumber }, (_, index) => (
                                                <i
                                                    key={index}
                                                    className={`bi bi-star${selectedDishStars[index] ? '-fill' : ''}`}
                                                    style={{
                                                        fontSize: 34,
                                                        cursor: "pointer",
                                                        color: selectedDishStars[index] ? '#FDCC0D' : 'lightgray',
                                                        padding: '3px',
                                                        borderRadius: '50%',
                                                        marginRight: 30
                                                    }}
                                                    onClick={() => handleDishStarClick(index)}
                                                ></i>
                                            ))}
                                        </div>
                                    </div>
                                    <div className='service-stars'>
                                        <p style={{
                                            fontSize: 15,
                                            fontWeight: 500,
                                            fontFamily: 'Poppins',
                                        }}>Rapport qualité-prix</p>
                                        <div>
                                            {maxStarsNumber && Array.from({ length: maxStarsNumber }, (_, index) => (
                                                <i
                                                    key={index}
                                                    className={`bi bi-star${selectedReportStars[index] ? '-fill' : ''}`}
                                                    style={{
                                                        fontSize: 34,
                                                        cursor: "pointer",
                                                        color: selectedReportStars[index] ? '#FDCC0D' : 'lightgray',
                                                        padding: '3px',
                                                        borderRadius: '50%',
                                                        marginRight: 30
                                                    }}
                                                    onClick={() => handleReportStarClick(index)}
                                                ></i>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            )}
                           {
                            isShowNextReviewSection && (
                                <div>
                                    {/* form message review */}
                                    <form>
                                        <div className='form-group mb-2'>
                                            <label htmlFor='message_review'></label>
                                            <textarea
                                                id="message_review"
                                                className="form-control"
                                                rows="3"
                                                placeholder='Message'
                                                value={messageReview}
                                                onChange={handleChangeMessageReview}
                                            ></textarea>
                                        </div>
                                        <div className='form-group mb-2'>
                                            <label htmlFor='email_field'></label>
                                            <input
                                                type='email'
                                                id='email_field'
                                                className='form-control'
                                                placeholder='Email*'
                                                required
                                                value={emailReview}
                                                onChange={handleChangeEmailReview}
                                            />
                                        </div>
                                        <div>
                                            {messageRequiredFieldsReview && <p className='text text-danger'
                                                onClick={() => setMessageRequiredFieldsReview(false)}>
                                                    {messageRequiredFieldsReview}
                                                </p>
                                            }
                                        </div>
                                        <div class="d-grid gap-2 col-6 mx-auto">
                                            <button 
                                                type="button"
                                                style={{
                                                    fontSize: "14px",
                                                    fontWeight: "500",
                                                    fontFamily: "Poppins",
                                                    borderRadius: "20px",
                                                    marginTop: "5px",
                                                    padding: "10px",
                                                    background: "linear-gradient(to right, #FCC546, #FDD681)",
                                                    border: 'none'
                                                }}
                                            onClick={handleSubmitAddReview}
                                            >Continuer </button>
                                        </div>
                                    </form>
                                </div>
                            )
                           }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
