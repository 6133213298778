import React, { useEffect, useState } from 'react'
import Header from '../common/components/Header'
import { useDispatch, useSelector } from 'react-redux';
import './PaymentProcess.css';
import { createOperationService, createOperationSharedByChoiceService, createOperationSharedByplitService, createOperationSharedBySplitService } from '../common/apiServices/operationsService';
import { useNavigate } from 'react-router-dom';
import { countAllTotals, formatPrice, getAllProductsFromAllOrdersByTable } from './../common/helpers/utils';
import { addToCart } from '../redux/cartSlice';
import { addOperationInfos } from '../redux/operationSlice';
import { getOrdersByTableService } from '../common/apiServices/orders/order.service';
import InsertCouponModal from './InsertCouponModal';
import PourboireModal from './PourboireModal';

export default function PaymentProcess() {
    const { products } = useSelector((state) => state.cart?.cartItems)
    const operationsInfos = useSelector((state) => state?.operation?.operationInfos)

    const {id_table, num_table, type_payment_table, type_shared_payment} = useSelector((state) => state?.operation?.operationInfos?.tableInfos)
    const clientInfosSelector = useSelector((state) => state?.operation?.operationInfos?.clientInfos)
    const navigate = useNavigate();

    const dispatch = useDispatch()

    // START CUSTOM POURBOIR PROCESS
    // handle pourboire
    const [pourboire, setPourboire] = useState(0);
    const [isOtherPourboire, setIsOtherPourboire] = useState(false);
    const [customNumberValue, setCustomNumberValue] = useState('');
    const [showCustomPourboireValModal, setShowCustomPourboireValModal] = useState(false);

    useEffect(() => {
        // allow closing modal when click outside
        const handleOutsideClick = (e) => {
            if (showCustomPourboireValModal === true && e.target.classList.contains('modal')) {
                setShowCustomPourboireValModal(false);
            }
        };

        document.addEventListener('click', handleOutsideClick);

        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, [showCustomPourboireValModal]);

    const handlePourboire = (pourboireValue) => {
        setIsOtherPourboire(false);
        if(pourboire === 0){
            setPourboire(pourboireValue)
        }else{
            setPourboire(0)
        }
        
    }

    // handle custom pourboire price value
    const handleChangeCustomNumberValue = (numValue) => {
        setCustomNumberValue((prev) => prev.concat(numValue))
    }

    const handleSubmitCustomNumberVlaue = () => {
        setPourboire(customNumberValue)
        // setCustomNumberValue('')

        // close popup
        setShowCustomPourboireValModal(false);
    }

    const handleRemoveCustomPourboireText = () => {
        setCustomNumberValue((currentText) => {
            return currentText.slice(0, currentText.length - 1)
        });

    }
    // END CUSTOM POURBOIR PROCESS

    const calculateSubTotal = () => {
        if(operationsInfos?.tableInfos && operationsInfos?.clientInfos){
            const {tableInfos, clientInfos} = operationsInfos;
            if(tableInfos?.type_payment_table === "OWN"){
                // return products.reduce((total, prod) => total + (prod.price * prod.quantity), 0);
                // return countAllTotals(products)
                return countAllTotals(getAllProductsFromAllOrdersByTable(ordersDataByTableList))
            }else{
                if(tableInfos?.type_shared_payment === "CHOICE"){
                    // return clientInfos?.selectedProdsToPay.reduce((total, prod) => total + (prod.price * prod.quantity), 0);
                    console.log("clientInfos?.selectedProdsToPay :", clientInfos?.selectedProdsToPay)
                    return countAllTotals(clientInfos?.selectedProdsToPay)
                }else{
                    // return clientInfos?.splittedAmountToPay
                    console.log("clientInfos?.splittedAmountToPay :", clientInfos?.splittedAmountToPay)
                    // return countAllTotals(clientInfos?.splittedAmountToPay)
                    return clientInfos?.splittedAmountToPay
                }
            }
        }
    }

    const calculateTotal = () => {
        if(operationsInfos?.tableInfos){
            const {tableInfos, clientInfos} = operationsInfos;
            if(tableInfos?.type_payment_table === "OWN"){
                return countAllTotals(getAllProductsFromAllOrdersByTable(ordersDataByTableList))
                // return countAllTotals(products) + Number(pourboire);
            }else{
                if(tableInfos?.type_shared_payment === "CHOICE"){
                    // return clientInfos?.selectedProdsToPay?.reduce((total, prod) => total + (prod.price * prod.quantity), 0) + Number(pourboire);
                    return countAllTotals(getAllProductsFromAllOrdersByTable(clientInfos?.selectedProdsToPay))
                }else{
                    return countAllTotals(getAllProductsFromAllOrdersByTable(clientInfos?.splittedAmountToPay))
                    // return clientInfos?.splittedAmountToPay + Number(pourboire)
                }
            }
        }
    }

    const sendPayAllOperation = () => {
        const bodyData = {
            tableInfos: {
                id_table: id_table,
                num_table: num_table,
                type_payment_table: type_payment_table,
                type_shared_payment: type_shared_payment,
            },
            payment_method: "visa",
            num_card: `1723 3445 2135 ${Math.floor(Math.random() * 9000)}`,
            pourboire: pourboire && Number(pourboire),
            // subTotal: countAllTotals(getAllProductsFromAllOrdersByTable(ordersDataByTableList)),
            subTotal: formatPrice(calculateSubTotal() + Number(pourboire) - (totalAMountByCouponReduction() > 0 && totalAMountByCouponReduction())),
            total: countAllTotals(getAllProductsFromAllOrdersByTable(ordersDataByTableList)) + Number(pourboire),
            status_id: 5, // by default set as "id" of "en attente".
            status_pos: "Non Notifié",
            client_id: clientInfosSelector?.id_client,
        }

        // call service here and pass it a body params
        createOperationService(bodyData)
            .then(() => {
                // navigate('/');
                navigate('/payment-success');

                // clear cart items from locaStorage synchronizing redux store (closed by order)
                let cartItemsStorage = JSON.parse(localStorage.getItem('cart'));
                cartItemsStorage = {
                    ...cartItemsStorage,
                    products: [],
                    count: 0
                }
                localStorage.setItem('cart', JSON.stringify(cartItemsStorage));
                dispatch(addToCart(cartItemsStorage));
                
                // clear table & client_infos
                let operationInfosStorage = JSON.parse(localStorage.getItem('operationInfos'));
                if(operationInfosStorage){
                    operationInfosStorage = {
                        ...operationInfosStorage,
                        clientInfos: {},
                        tableInfos: {},
                        receiptInfos: {
                            paidAmount: formatPrice(calculateSubTotal() + Number(pourboire) - (totalAMountByCouponReduction() > 0 && totalAMountByCouponReduction())),
                            pourboire: bodyData.pourboire
                        }
                    }

                    // Save the updated clientInfos back to localStorage
                    localStorage.setItem('operationInfos', JSON.stringify(operationInfosStorage));
                }
                // dispatch action to synch redux store
                dispatch(addOperationInfos(operationInfosStorage))

            })
            .catch((err) => console.log(err))
    }

    const sendPaySharedByChoiceOperation = () => {
        const bodyData = {
            tableInfos: {
                id_table: id_table,
                num_table: num_table,
                type_payment_table: type_payment_table,
                type_shared_payment: type_shared_payment,
            },
            payment_method: "visa",
            num_card: `1723 3445 2135 ${Math.floor(Math.random() * 9000)}`,
            pourboire: pourboire && Number(pourboire),
            subTotal: calculateSubTotal(),
            total: calculateSubTotal() + Number(pourboire),
            status_id: 5, // by default set as "id" of "en attente".
            status_pos: "Non Notifié",
            client_id: clientInfosSelector?.id_client,
            totalOfTotalsToPay: countAllTotals(getAllProductsFromAllOrdersByTable(ordersDataByTableList)),
            ordersOfSelectedProductsToPay: clientInfosSelector?.selectedProdsToPay
        }

        // call service here and pass it a body params
        createOperationSharedByChoiceService(bodyData)
            .then((data) => {
                if(data?.code === 0){
                    // here clear all table and client {}
                    // clear cart items from locaStorage synchronizing redux store (closed by order)
                    let cartItemsStorage = JSON.parse(localStorage.getItem('cart'));
                    cartItemsStorage = {
                        ...cartItemsStorage,
                        products: [],
                        count: 0
                    }
                    localStorage.setItem('cart', JSON.stringify(cartItemsStorage));
                    dispatch(addToCart(cartItemsStorage));

                    // navigate to home
                    // navigate('/');
                    navigate('/payment-success');
                }else{
                    // here clear just selectedProductsToPay []
                    let operationInfosStorage = JSON.parse(localStorage.getItem('operationInfos'));
                    if(operationInfosStorage){
                        operationInfosStorage = {
                            ...operationInfosStorage,
                            clientInfos: {
                                ...operationInfosStorage.clientInfos,
                                selectedProdsToPay: []
                            }
                        }
    
                        // Save the updated clientInfos back to localStorage
                        localStorage.setItem('operationInfos', JSON.stringify(operationInfosStorage));
                    }
                    // dispatch action to synch redux store
                    dispatch(addOperationInfos(operationInfosStorage))

                    // navigate to home
                    // navigate('/');
                    navigate('/payment-success');
                }
            })
            .catch((err) => console.log(err))
    }

    const sendPaySharedBySplitOperation = () => {
        const bodyData = {
            tableInfos: {
                id_table: id_table,
                num_table: num_table,
                type_payment_table: type_payment_table,
                type_shared_payment: type_shared_payment,
            },
            payment_method: "visa",
            num_card: `1723 3445 2135 ${Math.floor(Math.random() * 9000)}`,
            pourboire: pourboire && Number(pourboire),
            subTotal: calculateSubTotal(),
            total: calculateSubTotal() + Number(pourboire),
            status_id: 5, // by default set as "id" of "en attente".
            status_pos: "Non Notifié",
            client_id: clientInfosSelector?.id_client,
            totalOfTotalsToPay: countAllTotals(getAllProductsFromAllOrdersByTable(ordersDataByTableList)),
            ordersOfSplittedAmountToPay: ordersDataByTableList && ordersDataByTableList.map(item => item.id_order)
        }

        // call service here and pass it a body params
        createOperationSharedBySplitService(bodyData)
            .then((data) => {
                if(data?.code === 0){
                    // here clear all table and client {}
                    // clear cart items from locaStorage synchronizing redux store (closed by order)
                    let cartItemsStorage = JSON.parse(localStorage.getItem('cart'));
                    cartItemsStorage = {
                        ...cartItemsStorage,
                        products: [],
                        count: 0
                    }
                    localStorage.setItem('cart', JSON.stringify(cartItemsStorage));
                    dispatch(addToCart(cartItemsStorage));

                    // here clear all clientInfos and tableInfos
                    let operationInfosStorage = JSON.parse(localStorage.getItem('operationInfos'));
                    if(operationInfosStorage){
                        operationInfosStorage = {
                            ...operationInfosStorage,
                            clientInfos: {},
                            tableInfos: {},
                            receiptInfos: {
                                paidAmount: formatPrice(calculateSubTotal() + Number(pourboire) - (totalAMountByCouponReduction() > 0 && totalAMountByCouponReduction()))
                            }
                        }
    
                        // Save the updated clientInfos back to localStorage
                        localStorage.setItem('operationInfos', JSON.stringify(operationInfosStorage));
                    }
                    // dispatch action to synch redux store
                    dispatch(addOperationInfos(operationInfosStorage))

                    // navigate to home
                    // navigate('/');
                    navigate('/payment-success');
                }else{
                    // here clear just selectedProductsToPay []
                    let operationInfosStorage = JSON.parse(localStorage.getItem('operationInfos'));
                    if(operationInfosStorage){
                        operationInfosStorage = {
                            ...operationInfosStorage,
                            clientInfos: {
                                ...operationInfosStorage.clientInfos,
                                splittedAmountToPay: 0
                            }
                        }
    
                        // Save the updated clientInfos back to localStorage
                        localStorage.setItem('operationInfos', JSON.stringify(operationInfosStorage));
                    }
                    // dispatch action to synch redux store
                    dispatch(addOperationInfos(operationInfosStorage))

                    // navigate to home
                    // navigate('/');
                    navigate('/payment-success');
                }
            })
            .catch((err) => console.log(err))
    }

    const handleCreateOperationByRightOption = () => {
        if(type_payment_table === "OWN" && type_shared_payment === null){
            sendPayAllOperation();
        }else{
            if(type_shared_payment === "CHOICE"){
                sendPaySharedByChoiceOperation()
            }else{
                sendPaySharedBySplitOperation()
            }
        }
    }
    // show total to pay all products of all orders by table
    const [ordersDataByTableList, setOrdersDataByTableList] = useState([]);

    // add useEffect 2 to handle Total products by table api
    useEffect(() => {
        // call api get all products of all orders by table where not 'free'
        getOrdersByTableService(id_table)
            .then((orders) => {
                setOrdersDataByTableList(orders);
            })
            .catch((err) => console.log(err))
    }, [])

    // coupon process
    const [showModalInsertCoupon, setShowModalInsertCoupon] = useState(false);
    const [isCouponValid, setIsCouponValid] = useState(false);
    const [couponData, setCouponData] = useState(null);

    const handleSelectCoupon = () => {
        setShowModalInsertCoupon(true)
    }

    const totalAMountByCouponReduction = () => {
        return formatPrice(calculateSubTotal() * (couponData?.amountPromotion/100))
    }

    // pourboire state
    const [showPourboireModal, setShowPourboireModal] = useState(false);
    
    const calculatedTotalToPay = () => {
        return calculateSubTotal() + Number(pourboire) - (totalAMountByCouponReduction() > 0 && totalAMountByCouponReduction())
    }
    return (
        <div style={{ backgroundColor: '#f7f6fa' }}>
            <Header redirectTo="/payment" />
            {/* Start payment method section */}
            <div className='container-fluid p-3 bg-white'
                style={{
                    marginTop: "10px",
                    borderRadius: "5px",
                    boxShadow: "rgba(100, 100, 111, 0.1) 0px 7px 29px 0px inset",
                }}
            >
                <p style={{
                    fontFamily: "Poppins",
                    fontSize: "15px",
                    fontWeight: "800",
                    lineHeight: "32px",
                    paddingLeft: "15px"

                }}>Moyen de paiement</p>
                <ul class="list-group">
                    
                    <li class="list-group-item"
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            border: "none"
                        }}
                    >
                        <label class="form-check-label" for="secondRadio"
                            style={{
                                fontSize: "22px",
                                fontWeight: "600"
                            }}
                        >Carte de crédit
                        </label>
                        <input class="form-check-input me-1" type="radio" checked name="paymentMethod" value="" id="secondRadio" />
                    </li>
                    <li class="list-group-item"
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            border: "none"
                        }}
                    >
                        <div>
                            <img src="/images/pay-radio-icon.svg" alt="pay_img" width={50} height={30}  /> / 
                            <img src="/images/google-pay-icon.svg" alt="pay_img" width={50} height={30} style={{marginLeft: 7}}/>
                        </div>
                        <input class="form-check-input me-1" type="radio" name="paymentMethod" value="" id="firstRadio" />
                    </li>
                </ul>
            </div>
            {/* until here is good go to list 3 */}
            <div className='container-fluid p-3 bg-white'
                style={{
                    marginTop: "10px",
                    borderRadius: "5px",
                    boxShadow: "rgba(100, 100, 111, 0.1) 0px 7px 29px 0px inset",
                }}
            >
                <div className=''>
                    <ul class="list-group list-group-flush">
                        <li class="list-item"
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                padding: "14px",
                                margin: 0
                            }}
                        >
                            <p style={{
                                fontSize: "15px",
                                fontFamily: "Poppins",
                                margin: 0
                            }}
                            >
                                <b>Sous-total</b>
                            </p>
                            <p style={{
                                fontSize: "15px",
                                fontFamily: "Poppins",
                                margin: 0
                            }}
                            >
                                <b>{`${formatPrice(calculateSubTotal())} Dhs`}</b>
                            </p>
                        </li>
                        <li class="list-group-item"
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                padding: "14px",
                                margin: 0
                            }}
                        >
                            <p style={{
                                fontSize: "15px",
                                fontFamily: "Poppins",
                                margin: 0
                            }}
                            >
                                Pourboire
                            </p>
                            <p style={{
                                fontSize: "15px",
                                fontFamily: "Poppins",
                                margin: 0
                            }}
                            >
                                {`${formatPrice(pourboire)} Dhs`}
                            </p>
                        </li>
                       {
                        couponData && (
                            <li class="list-group-item"
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    padding: "14px",
                                    margin: 0
                                }}
                            >
                                <p style={{
                                    fontSize: "15px",
                                    fontFamily: "Poppins",
                                    margin: 0
                                }}
                                >
                                    Réduction “{couponData?.name_coupon}”
                                </p>
                                <p style={{
                                    fontSize: "15px",
                                    fontFamily: "Poppins",
                                    margin: 0
                                }}
                                >
                                    {`- ${totalAMountByCouponReduction()} Dhs`}
                                </p>
                            </li>
                        )
                       }
                        <li class="list-group-item"
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                padding: "14px",
                                margin: 0
                            }}
                        >
                            <p style={{
                                fontSize: "18px",
                                fontWeight: "800",
                                fontFamily: "Poppins",
                                margin: 0
                            }}
                            >Total</p>
                            <p style={{
                                fontSize: "18px",
                                fontWeight: "800",
                                fontFamily: "Poppins",
                                margin: 0,
                                background: "linear-gradient(to right, #FCC546, #FDD681)",
                                WebkitBackgroundClip: 'text',
                                color: 'transparent',
                                display: 'inline-block',
                                WebkitTextFillColor: 'transparent', // For Safari
                            }}
                            >
                                {`${formatPrice(calculateSubTotal() + Number(pourboire) - (totalAMountByCouponReduction() > 0 && totalAMountByCouponReduction())) } Dhs`}
                            </p>
                        </li>
                    </ul>
                </div>
            </div>
            {/* coupon part */}
            <div style={{textAlign:'right', fontSize: 14, fontWeight: 700, cursor:'pointer', paddingRight: 25}}>
                <p onClick={handleSelectCoupon}>
                    <u>Entrer un coupon</u>
                </p>
            </div>
              {/* START MODAL */}
                {
                    showModalInsertCoupon && (
                        <InsertCouponModal
                            title="Entrer un coupon"
                            setShowModalInsertCoupon={setShowModalInsertCoupon}
                            setCouponData={setCouponData}
                            setIsCouponValid={setIsCouponValid}
                        />
                    )
                }
            {/* END MODAL */}
            {/* END coupon part */}
            {/* until here is good go to button */}
            <div className='container-fluid p-4 bg-white text text-center mt-1'>
                <button
                    className="btn btn-lg"
                    style={{
                        paddingLeft: "90px",
                        paddingRight: "90px",
                        paddingTop: '15px',
                        paddingBottom: '15px',
                        borderColor: "white",
                        background:"linear-gradient(to right, #FCC546, #FDD681)",
                        color: '#1E1E1E',
                        fontFamily: "Poppins",
                        fontSize: "16px",
                        fontWeight: "600",
                    }}
                    type="button"
                    // onClick={handleCreateOperationByRightOption}
                    onClick={() => setShowPourboireModal(true)}
                    disabled={(calculateSubTotal() + Number(pourboire)) === 0}
                >
                    Payer 
                    {` ${formatPrice(calculateSubTotal() + Number(pourboire) - (totalAMountByCouponReduction() > 0 && totalAMountByCouponReduction())) } Dhs`}
                </button>
                {/* Open Pourboire Modal */}
                {
                    showPourboireModal && (
                        <PourboireModal
                            setShowPourboireModal={setShowPourboireModal}
                            calculatedTotal={calculatedTotalToPay}
                            setPourboire={setPourboire}
                            handleCreateOperationByRightOption={handleCreateOperationByRightOption}
                        />
                    )
                }
                {/* END Pourboire Modal */}
            </div>
        </div>
    )
}
