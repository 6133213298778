import { useState } from 'react'
import { formatPrice } from '../common/helpers/utils'
import CustomTipModal from './CustomTipModal';

export default function PourboireModal({
    setShowPourboireModal,
    calculatedTotal,
    setPourboire,
    handleCreateOperationByRightOption
}) {
    const [tipValue, setTipValue] = useState(0);
    
    const handlePourboireVal = (val) => {
        setTipValue(val)
        setPourboire(val)
    }
    const cancelPourboire = () => {
        setTipValue(0);
        setPourboire(0);
    }

    const handleConfirmPayByPourboire = () => {
        setPourboire(tipValue);
        handleCreateOperationByRightOption();
        setShowPourboireModal(false);
    }

    //  handle state cutom pourboire
    const [showCustomTipModal, setShowCustomTipModal] = useState(false);


    return (
        <div
            className="modal fade show"
            id="shareModal"
            tabindex="-1"
            aria-labelledby="shareModalLabel"
            aria-hidden="true"
            style={{
                // this stye what make modal display
                display: 'block',
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
            }}
        >
            <div class="modal-dialog"
                style={{
                    position: "fixed",
                    bottom: -15,
                    left: "49%",
                    transform: "translateX(-50%)",
                    width: "100%",
                }}
            >
                <div class="modal-content"
                    style={{ borderColor: "none", }}
                >
                    <div class="modal-header">
                       <div>
                        {/* keep emty to get space busy for flex */}
                       </div>
                        <h5
                            class="modal-title text text-center"
                            id="shareModalLabel"
                            style={{
                                fontFamily: "Poppins",
                                fontSize: 27,
                                fontWeight: 600,
                                textAlign:'center'
                            }}
                        >Dites merci à <span style={{color: '#FDD681'}}>l’équipe</span> <br/> avec un pourboire</h5>
                        <img
                            src="images/close-icon.svg"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            alt="close-modal-icon"
                            style={{ cursor: "pointer" }}
                            onClick={() => setShowPourboireModal(false)}
                        />
                    </div>
                    <div class="modal-body">
                        <div class="d-grid gap-2">
                            <p style={{
                                fontSize: 14,
                                fontWeight: 400,
                                color: '#0000008A'
                            }}><span className='text text-warning'>100%</span> de votre pouboire est reversé à l’équipe du restaurant</p>
                               
                            <div className='tips-options' style={{
                                display: 'flex',
                                alignItems:'center',
                                justifyContent: 'space-around'
                            }}>
                                <div style={{
                                    background: "linear-gradient(to right, #FCC546, #FDD681)",
                                    height: 90,
                                    width: 131,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    borderRadius: 20,
                                    position: 'relative',
                                    cursor:'pointer'
                                }}
                                    onClick={() => handlePourboireVal('5')}
                                >
                                    <p>5 Dhs</p>
                                    <img src='/images/pourboire/Tips5.png' style={{
                                        position: 'absolute',
                                        bottom: -15
                                    }}/>
                                </div>
                                <div style={{
                                    background: "linear-gradient(to right, #FCC546, #FDD681)",
                                    height: 90,
                                    width: 131,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    borderRadius: 20,
                                    position: 'relative',
                                    cursor:'pointer'
                                }}
                                    onClick={() => handlePourboireVal('10')}
                                >
                                    <p>10 Dhs</p>
                                    <img src='/images/pourboire/Tips10.png' style={{
                                        position: 'absolute',
                                        bottom: -15
                                    }}/>
                                </div>
                                <div style={{
                                    background: "linear-gradient(to right, #FCC546, #FDD681)",
                                    height: 90,
                                    width: 131,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    borderRadius: 20,
                                    position: 'relative',
                                    cursor:'pointer'
                                }}
                                    onClick={() => handlePourboireVal('15')}
                                >
                                    <p>15 Dhs</p>
                                    <img src='/images/pourboire/Tips15.png' style={{
                                        position: 'absolute',
                                        bottom: -15
                                    }}/>
                                </div>
                            </div> 
                            <div style={{
                                display: 'flex',
                                justifyContent: 'center',
                                marginTop: 15
                            }}>
                                <button 
                                 style={{
                                    fontSize: 14,
                                    fontWeight: 400,
                                    fontFamily: "Poppins",
                                    borderRadius: "20px",
                                    border: 'solid black 0.5px',
                                    marginTop: "5px",
                                    padding: "10px",
                                    paddingLeft: "30px",
                                    paddingRight: "30px",
                                    background: "#FFFFFF",
                                    color: '#000000',
                                    marginRight: 5,
                                }}
                                onClick={() => setShowCustomTipModal(true)}

                                >Montant personnalisé</button>
                                <button 
                                 style={{
                                    fontSize: 14,
                                    fontWeight: 400,
                                    fontFamily: "Poppins",
                                    borderRadius: "20px",
                                    border: 'none',
                                    marginTop: "5px",
                                    padding: "10px",
                                    paddingLeft: "30px",
                                    paddingRight: "30px",
                                    background: "#0000008A",
                                    color: '#FFFFFF',
                                    marginLeft: 5,
                                }}
                                onClick={cancelPourboire}
                                >Pas de pourboire</button>
                            </div>
                            {/*Open Custom Pourboire  */}
                            {
                                showCustomTipModal && (
                                    <CustomTipModal
                                        title={"Montant personnalisé"}
                                        setShowCustomTipModal={setShowCustomTipModal}
                                        setPourboire={setPourboire}
                                    />
                                )
                            }
                            {/*END Custom Pourboire  */}
                            <div className='text text-center mt-3'>
                                <p style={{fontSize: 14, fontWeight: 700, fontFamily: 'Poppins'}}>
                                    Votre paiement: {formatPrice(calculatedTotal() + Number(tipValue))} Dhs
                                </p>
                            </div>
                            <button class="" 
                                type="submit"
                                style={{
                                    fontSize: "14px",
                                    fontWeight: "500",
                                    fontFamily: "Poppins",
                                    borderRadius: "20px",
                                    padding: "10px",
                                    background: "#000000",
                                    color: '#FFFFFF',
                                    width: 300,
                                    margin: '0 auto'
                                }}
                                onClick={handleConfirmPayByPourboire}
                            >Payer mon addition </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
