import { API_URL } from "../../../env"

export const createOrderService = (bodyData) => {
    return fetch(`${API_URL}/api/orders/create`, {
        method: "POST",
        headers:{
            "Content-Type": "application/json"
        },
        body: JSON.stringify(bodyData),
    })
    .then((res) => res.json())
}

export const getOrdersByTableService = async (id_table) => {
    try {
      const response = await fetch(`${API_URL}/api/orders/show?id_table=${id_table}`,{
        method: "GET",
      });
      if (!response.ok) {
        throw new Error('Failed to fetch cart items');
      }
      const data = await response.json();
      return data.ordersByTable;
    } catch (error) {
      throw error;
    }
  };

export const addSharedByChoiceOptionToAllOrdersByTableService = async (bodyData) => {
  return fetch(`${API_URL}/api/orders/select-shared-option`, {
    method: "PUT",
    headers:{
        "Content-Type": "application/json"
    },
    body: JSON.stringify(bodyData),
})
.then((res) => res.json())
}

export const addSelectedProductsToPayByChoiceService = async (bodyData) => {
  return fetch(`${API_URL}/api/orders/select-products-topay`, {
    method: "PUT",
    headers:{
        "Content-Type": "application/json"
    },
    body: JSON.stringify(bodyData),
})
.then((res) => res.json())
}

export const addPersonNumberToAllSplitOrdersByTableService = async (bodyData) => {
  return fetch(`${API_URL}/api/orders/add-split-persons-number`, {
    method: "PUT",
    headers:{
        "Content-Type": "application/json"
    },
    body: JSON.stringify(bodyData),
})
.then((res) => res.json())
}

export const addSharedBySplitOptionToAllOrdersByTableService = async (bodyData) => {
  return fetch(`${API_URL}/api/orders/select-shared-split-option`, {
    method: "PUT",
    headers:{
        "Content-Type": "application/json"
    },
    body: JSON.stringify(bodyData),
})
.then((res) => res.json())
}

export const addNewPersonSharedSplitByTableService = async (id_table) => {
  try {
    const response = await fetch(`${API_URL}/api/orders/add-new-person-to-shared-split?id_table=${id_table}`,{
      method: "GET",
    });
    if (!response.ok) {
      throw new Error('Failed to fetch cart items');
    }
    const data = await response.json();
    // return data;
    return data;
  } catch (error) {
    throw error;
  }
};

export const removePersonFromSharedSplitByTableService = async (id_table) => {
  try {
    const response = await fetch(`${API_URL}/api/orders/remove-person-from-shared-split?id_table=${id_table}`,{
      method: "GET",
    });
    if (!response.ok) {
      throw new Error('Failed to fetch cart items');
    }
    const data = await response.json();
    // return data;
    return data;
  } catch (error) {
    throw error;
  }
};

export const addSplittedAmountToPayToSharedOrdersService = async (bodyData) => {
  return fetch(`${API_URL}/api/orders/add-split-amount-topay`, {
    method: "PUT",
    headers:{
        "Content-Type": "application/json"
    },
    body: JSON.stringify(bodyData),
})
.then((res) => res.json())
}