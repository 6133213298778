import { API_URL } from "../../../env"

export const createClientService = async (bodyObj) => {
    try {
      const response = await fetch(`${API_URL}/api/clients/create`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(bodyObj),
      });
      if (!response.ok) {
        throw new Error('Failed to add product to cart');
      }
      const data = await response.json();
      return data;
    } catch (error) {
      throw error;
    }
};